export class ErrorModal {
  private modal: HTMLDivElement;
  private onClose: (() => void) | null = null;

  constructor() {
    this.modal = document.getElementById('errorModal') as HTMLDivElement;
    if (!this.modal) {
      throw new Error('Error modal element not found');
    }

    // Add event listener to close button
    const closeButton = this.modal.querySelector('button');
    if (!closeButton) {
      throw new Error('Error modal close button not found');
    }
    
    closeButton.addEventListener('click', () => {
      this.hide();
    });
  }

  show(title: string, message: string, onClose?: () => void) {
    const heading = this.modal.querySelector('h2');
    const messageEl = this.modal.querySelector('#errorMessage');
    
    if (!heading || !messageEl) {
      throw new Error('Error modal elements not found');
    }

    heading.textContent = title;
    messageEl.textContent = message;
    this.modal.classList.remove('hidden');
    
    // Store onClose callback
    this.onClose = onClose || null;
  }

  hide() {
    this.modal.classList.add('hidden');
    
    // Execute onClose callback if exists
    if (this.onClose) {
      this.onClose();
      this.onClose = null;
    }
  }
} 