export class LogEntry {
    type: 'dialogue' | 'lore' | 'system';
    speaker: string;
    content: string;
    metadata: Record<string, any>;
    timestamp: number;

    constructor({type, speaker, content, metadata = {}, timestamp = Date.now()}: {type: 'dialogue' | 'lore' | 'system', speaker: string, content: string, metadata?: Record<string, any>, timestamp?: number}) {
        this.type = type;
        this.speaker = speaker;
        this.content = content;
        this.metadata = metadata;
        this.timestamp = timestamp;
    }

    toJSON() {
        return {
            type: this.type,
            speaker: this.speaker,
            content: this.content,
            metadata: this.metadata,
            timestamp: this.timestamp
        };
    }

    static fromJSON(data: any) {
        return new LogEntry({
            type: data.type,
            speaker: data.speaker,
            content: data.content,
            metadata: data.metadata || {},
            timestamp: data.timestamp
        });
    }
};

export type LevelConnection = {
    connectionId: string;
    fromId: string;
    toId: string;
    description: string;
}

export class WorldBuilding {
    worldBuilding: string;
    levels: {uid: string, name: string, description: string, seen: boolean}[];
    connections: LevelConnection[];
    startingLevelId: string;

    constructor({worldBuilding, levels, connections, startingLevelId}: {worldBuilding: string, levels: {uid: string, name: string, description: string, seen: boolean}[], connections: LevelConnection[], startingLevelId: string}) {
        this.worldBuilding = worldBuilding;
        this.levels = levels;
        this.connections = connections;
        this.startingLevelId = startingLevelId;
    }

    getConnectionsForLevel(levelId: string) {
        return this.connections.filter(connection => connection.fromId === levelId || connection.toId === levelId);
    }

    getLevel(levelId: string) {
        return this.levels.find(level => level.uid === levelId);
    }

    static fromJSON(data: any) {
        return new WorldBuilding({
            worldBuilding: data.worldBuilding,
            levels: data.levels,
            connections: data.connections,
            startingLevelId: data.startingLevelId
        });
    }

    toJSON() {
        return {
            worldBuilding: this.worldBuilding,
            levels: this.levels,
            connections: this.connections,
            startingLevelId: this.startingLevelId
        };
    }
};

export type LogFilter = {
    type?: string;
    speaker?: string;
    since?: number;
};

export class LoreLog {
    entries: LogEntry[];
    worldBuilding: WorldBuilding;
    listeners: Set<Function>;

    constructor({entries, worldBuilding}: {entries: LogEntry[], worldBuilding: WorldBuilding}) {
        this.entries = entries || [];
        this.worldBuilding = worldBuilding;
        this.listeners = new Set();
    }

    addEntry(entry: LogEntry) {
        this.entries.push(entry);
        this.notifyListeners(entry);
    }

    getEntries(filter: LogFilter = {}) {
        let filtered = [...this.entries];

        if (filter.type) {
            filtered = filtered.filter(entry => entry.type === filter.type);
        }
        const since = filter.since;
        if (since) {
            filtered = filtered.filter(entry => entry.timestamp >= since);
        }
        if (filter.speaker) {
            filtered = filtered.filter(entry => entry.speaker === filter.speaker);
        }

        return filtered;
    }

    getRecentDialogueContext(limit = 10) {
        return this.entries
            .filter(entry => entry.type === 'dialogue')
            .slice(-limit)
            .map(entry => `${entry.speaker}: ${entry.content}`)
            .join('\n');
    }

    subscribe(callback: (entry: LogEntry) => void) {
        this.listeners.add(callback);
        return () => this.listeners.delete(callback);
    }

    notifyListeners(entry: LogEntry) {
        this.listeners.forEach(callback => callback(entry));
    }

    clear() {
        this.entries = [];
    }

    toJSON() {
        return {
            entries: this.entries,
            worldBuilding: this.worldBuilding
        };
    }

    static fromJSON(data: any) {
        return new LoreLog({
            entries: data.entries.map((entryData: any) => LogEntry.fromJSON(entryData)),
            worldBuilding: WorldBuilding.fromJSON(data.worldBuilding)
        });
    }
} 