import { Game } from "../../client/src/Game";

interface EntityAutomation {
  randomWalk?: {
    moveChance: number;
    stepInterval: number;
  };
  follow_player?: {
    maxDistance: number;
  };
  run_from_player?: {
    minDistance: number;
  };
  dialogue?: {
    chance: number;
    prompt: string;
    interval?: number;
    wait?: boolean;
  };
}

export interface SwitchLevels {
  targetLevelId: string;
  connectionId: string;
}

export interface EntityData {
  id: string;
  name: string;
  x: number;
  y: number;
  blocking?: boolean;
  light?: number;
  color: string;
  svg: string;
  description?: string;
  automation?: EntityAutomation;
  switchLevels?: SwitchLevels;
  uniqueId?: string;
  message?: string;
  dialogueHistory?: Dialogue[];
  names?: string[];
  lastAutomationTime?: number;
  dialogueTimeAccumulator?: number;
  maxHistoryLength?: number;
  displayName?: string;
  maxInventorySize?: number;
  add_to_inventory?: string[];
  usableEntitiesData?: Record<string, any>;
  moveSpeed?: number;
}

// Constants
const MAX_HISTORY_LENGTH = 50000;
const DIALOGUE_COOLDOWN = 3000;
const PLAYER_INTERACTION_DISTANCE = 3;
const RANDOM_DIALOGUE_TIME_VARIANCE = 1000;
const MINIMUM_DIALOGUE_DELAY = 3500;
const UNIQUE_ID_LENGTH = 9;
const FEET_PER_UNIT = 5;
const DIRECTIONS = [
  { dx: 0, dy: -1 }, // up
  { dx: 0, dy: 1 }, // down
  { dx: -1, dy: 0 }, // left
  { dx: 1, dy: 0 }, // right
] as const;
const MAX_INVENTORY_SIZE = 20;

interface Dialogue {
  message: string;
  timestamp: number;
}

class Dialogue implements Dialogue {
    message: string;
    timestamp: number;

    constructor(message: string, timestamp: number) {
      this.message = message;
      this.timestamp = timestamp;
    }
}

export class Entity {
    static PromptingService: any = null;
    static speakingQueue: Entity[] = [];
    static currentlySpeakingEntity: Entity | null = null;
    static lastSpeakTimestamp: number = 0;
    static GLOBAL_SPEAK_COOLDOWN: number = 2000; // 2 seconds cooldown
  
    public x: number;
    public y: number;
    public id: string;
    public name: string;
    public description?: string;
    public blocking: boolean;
    public automation: EntityAutomation | null;
    public light: number;
    public color: string;
    public svg: string;
    public names: string[];
    public switchLevels?: SwitchLevels;
    public message?: string;
    public speaking: boolean;
    public lastAutomationTime: number;
    public dialogueTimeAccumulator: number;
    public dialogueHistory: Dialogue[];
    public maxHistoryLength: number;
    public hasInitiatedDialogue: boolean;
    public uniqueId: string;
    public displayName: string;
    public maxInventorySize: number;
    public inventory: Entity[];
    public velocityX: number;
    public velocityY: number;
    public moveSpeed: number;
  
    constructor(entityData: EntityData|Entity, x?: number, y?: number) {
      // Position validation and assignment
      if (isNaN(Number(entityData?.x)) && isNaN(Number(x))) {
          throw new Error(`Invalid x coordinate for entity ${entityData?.id}`);
      }
      if (isNaN(Number(entityData?.y)) && isNaN(Number(y))) {
          throw new Error(`Invalid y coordinate for entity ${entityData?.id}`);
      }
  
      // Position - use provided coordinates or fallback with validation
      this.x = !isNaN(Number(entityData?.x)) ? Number(entityData.x) : Number(x);
      this.y = !isNaN(Number(entityData?.y)) ? Number(entityData.y) : Number(y);
  
      // Core properties with proper type handling
      this.id = entityData?.id ?? '';
      this.name = entityData?.name ?? '';
      this.description = entityData?.description;
      this.blocking = Boolean(entityData?.blocking);
      this.automation = entityData?.automation || null;
      this.light = Number(entityData?.light) || 0;
      this.color = entityData?.color || "#FFF4E0";
      this.svg = entityData?.svg;
      this.names = entityData?.names || [];
      this.switchLevels = entityData?.switchLevels;
      this.message = entityData?.message;
      this.speaking = false;
      this.velocityX = 0;
      this.velocityY = 0;
      this.moveSpeed = entityData?.moveSpeed || 5;

      // Automation and timing
      this.lastAutomationTime = Number(entityData?.lastAutomationTime) || 0;
      this.dialogueTimeAccumulator = Number(entityData?.dialogueTimeAccumulator) || 0;
  
      // Dialogue related
      this.dialogueHistory = entityData?.dialogueHistory || [];
      this.maxHistoryLength = entityData?.maxHistoryLength || MAX_HISTORY_LENGTH;
      this.hasInitiatedDialogue = !entityData?.automation?.dialogue?.wait;
      
      // Identity
      this.uniqueId = entityData?.uniqueId || `${entityData?.id}_${Math.random()
          .toString(36)
          .substr(2, UNIQUE_ID_LENGTH)}`;
      this.displayName = entityData?.displayName || 
          entityData?.name || 
          (entityData?.names ? entityData?.names[Math.floor(Math.random() * entityData.names.length)] : null) || 
          entityData?.description || 
          "Unknown Entity";
  
      // Inventory
      this.maxInventorySize = entityData?.maxInventorySize || MAX_INVENTORY_SIZE;
      this.inventory = [];
  
      // Initialize inventory items if provided
      if (entityData && 'add_to_inventory' in entityData && entityData.add_to_inventory) {
        for (const itemId of entityData.add_to_inventory) {
            const itemData = entityData.usableEntitiesData?.[itemId];
            if (itemData) {
                const item = new Entity({ 
                    ...itemData,
                    id: itemId,
                    usableEntitiesData: entityData.usableEntitiesData
                }, this.x, this.y);
                this.addToInventory(item);
            } else {
                console.error(`Non-instantiated entity ${itemId} not found in usableEntitiesData`);
            }
        }
      }

      if (entityData && 'inventory' in entityData && entityData.inventory) {
        for (const item of entityData.inventory) {
            if (item.uniqueId) {
                this.addToInventory(new Entity(item));
            } else {
                console.error(`Non-instantiated entity ${item} in inventory`);
            }
        }
      }
    }

    toJSON() {
      const data: any = {};
      Object.assign(data, this);
      data.inventory = data.inventory.map((item: Entity) => item.toJSON());
      return data;
    }
  
    static setPromptingService(PromptingService: any): void {
      this.PromptingService = PromptingService;
    }
  
    isAdjacent(entity: Entity): boolean {
      const dx = Math.abs(this.x - entity.x);
      const dy = Math.abs(this.y - entity.y);
      return dx <= 1 && dy <= 1 && !(dx === 0 && dy === 0);
    }
  
    tick(deltaTime: number, game: Game): void {
        if (this.velocityX !== 0 || this.velocityY !== 0) {
            const secondsDelta = deltaTime / 1000;
            const newX = this.x + this.velocityX * secondsDelta / FEET_PER_UNIT;
            const newY = this.y + this.velocityY * secondsDelta / FEET_PER_UNIT;
            
            // Check if movement is valid
            const moveResult = game.isValidMove(this, newX, newY);
            
            if (moveResult.validX === this.x || moveResult.validY === this.y) {
                // If blocked in one direction, try to slide along the wall
                const magnitude = Math.sqrt(this.velocityX * this.velocityX + this.velocityY * this.velocityY);
                
                if (moveResult.validX === this.x) {
                    // Blocked horizontally, try full velocity vertically
                    const slideY = this.y + Math.sign(this.velocityY) * magnitude * secondsDelta / FEET_PER_UNIT;
                    const slideResult = game.isValidMove(this, this.x, slideY);
                    moveResult.validY = slideResult.validY;
                }
                
                if (moveResult.validY === this.y) {
                    // Blocked vertically, try full velocity horizontally
                    const slideX = this.x + Math.sign(this.velocityX) * magnitude * secondsDelta / FEET_PER_UNIT;
                    const slideResult = game.isValidMove(this, slideX, this.y);
                    moveResult.validX = slideResult.validX;
                }
            }
            
            // Update position to the final valid position
            this.x = moveResult.validX;
            this.y = moveResult.validY;
        }

        // Handle automated entity movement
        this.handleAutomations(deltaTime, game);
    }

    private handleAutomations(deltaTime: number, game: Game): void {
      this.lastAutomationTime += deltaTime;
      const automation = this.automation;
      if (!automation) return;
      this.handleRandomWalk(game);
      this.handleFollowPlayer(game);
      this.handleRunFromPlayer(game);
      this.handleDialogueAutomation(deltaTime, game);
    }

    private playerIsWithinInteractionDistance(game: Game): boolean {
      if (!game.player) return false;
      const dx = Math.abs(this.x - game.player.x);
      const dy = Math.abs(this.y - game.player.y);
      const distance = Math.max(dx, dy);
      return distance <= PLAYER_INTERACTION_DISTANCE;
    }

    private handleDialogueAutomation(deltaTime: number, game: Game): void {
      if (!this.automation?.dialogue) return;
      if (!this.playerIsWithinInteractionDistance(game)) return;

      const { chance, prompt } = this.automation.dialogue;
      this.dialogueTimeAccumulator = this.dialogueTimeAccumulator || 0;
      this.dialogueTimeAccumulator += deltaTime;

      if (this.checkIfGoodToSpeak(game) && Math.random() < chance) {
        this.generateDialogue(prompt, game);
      }
    }

    private isReadyForMovement(stepInterval: number): boolean {
      return this.lastAutomationTime >= stepInterval;
    }

    private handleRandomWalk(game: Game): void {
      if (!this.automation?.randomWalk) return;
      if (!this.isReadyForMovement(this.automation.randomWalk.stepInterval)) return;
      
      const { moveChance } = this.automation.randomWalk;
      if (Math.random() < moveChance) {
        // Generate random angle in radians (0 to 2π)
        const angle = Math.random() * 2 * Math.PI;
        
        // Calculate direction using trigonometry for true random direction
        this.velocityX = Math.cos(angle) * this.moveSpeed;
        this.velocityY = Math.sin(angle) * this.moveSpeed;
      } else {
        this.velocityX = 0;
        this.velocityY = 0;
      }
      
      this.lastAutomationTime = Entity.generateStepIntervalBasis(this.automation.randomWalk.stepInterval);
    }

    private handleFollowPlayer(game: Game): void {
      const player = game.player;
      if (!player) return;
      if (!this.automation?.follow_player) return;
      
      const { maxDistance } = this.automation.follow_player;
      const { distance, dx, dy } = this.getDistanceToPlayer(player);

      if (distance > maxDistance) {
        // Normalize the direction vector
        const length = Math.sqrt(dx * dx + dy * dy);
        const normalizedDx = dx / length;
        const normalizedDy = dy / length;
        
        // Set velocity in the direction of the player
        this.velocityX = normalizedDx * this.moveSpeed;
        this.velocityY = normalizedDy * this.moveSpeed;
      } else {
        // Stop moving when within maxDistance
        this.velocityX = 0;
        this.velocityY = 0;
      }
      
    }

    private handleRunFromPlayer(game: Game): void {
      const player = game.player;
      if (!player) return;
      if (!this.automation?.run_from_player) return;
      
      const { minDistance } = this.automation.run_from_player;
      const { distance, dx, dy } = this.getDistanceToPlayer(player);

      if (distance < minDistance) {
        // Normalize the direction vector (away from player)
        const length = Math.sqrt(dx * dx + dy * dy);
        const normalizedDx = dx / length;
        const normalizedDy = dy / length;
        
        // Set velocity away from the player
        this.velocityX = -normalizedDx * this.moveSpeed;
        this.velocityY = -normalizedDy * this.moveSpeed;
      } else {
        // Stop moving when beyond minDistance
        this.velocityX = 0;
        this.velocityY = 0;
      }
    }

    private getDistanceToPlayer(player: Entity): { distance: number; dx: number; dy: number } {
      const dx = player.x - this.x;
      const dy = player.y - this.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      return { distance, dx, dy };
    }



    private static generateStepIntervalBasis(interval: number): number {
      return interval*(Math.random()*0.2-0.1);
    }




  
    async generateDialogue(prompt: string, game: Game): Promise<void> {
        // Check queue
        if (!Entity.speakingQueue.includes(this)) {
            Entity.speakingQueue.push(this);
        }


        // Wait for turn in queue and don't start if we're already speaking to reduce token usage
        while (Entity.speakingQueue[0] !== this || (Entity.currentlySpeakingEntity !== null && Entity.currentlySpeakingEntity !== this)) {

          await new Promise(resolve => setTimeout(resolve, 100));
        }

        if (this.speaking) {
            Entity.speakingQueue.shift(); // Clear queue if already speaking
            return;
        }

        try {
            this.speaking = true;
            Entity.currentlySpeakingEntity = this;
            console.log(`${this.uniqueId} is speaking`);

            while (this.speaking) {
                if (!this.playerIsWithinInteractionDistance(game)) {
                  break;
                }
                if (!this.checkIfGoodToSpeak(game)) {
                    await new Promise((resolve) => setTimeout(resolve, 100));
                    continue;
                }
  
                // Pass current timestamp and lore along with the request
                const currentTime = Date.now();
                const response = await Entity.PromptingService.generateDialogue(
                    this, 
                    prompt, 
                    currentTime,
                    "This area's description: " + game.world.level.description
                );
                const { message, action } = response;

  
                if (message.length > 0 && !this.checkIfGoodToSpeak(game)) {
                    this.dialogueTimeAccumulator = 0;
                    Entity.lastSpeakTimestamp = Date.now(); // Set the timestamp when starting to speak
                    await new Promise((resolve) => setTimeout(resolve, 100));
                    break;
                } else {
                    this.dialogueTimeAccumulator = 0;
                    Entity.lastSpeakTimestamp = Date.now(); // Set the timestamp when starting to speak
                }
                
                // Handle new format with message and optional result
                if (message.length > 0) {
                    this.addToDialogueHistory(`${this.uniqueId}|${this.displayName}: ${message}`);
                    this.message = message;
                    game.showMessage(this);
                    game.logMessage(this.displayName, message, this.uniqueId, "dialogue");
                }

                // Handle the result if present
                if (action) {
                    await game.executeAction(action, this);
                }

                this.speaking = false;
            }
        } catch (error) {
            console.error("Failed to generate dialogue:", error);
        } finally {
            this.speaking = false;
            Entity.currentlySpeakingEntity = null;
            
            // Only remove from queue if we're still first in line
            if (Entity.speakingQueue[0] === this) {
              console.log(`${this.uniqueId} removed from queue`);
                Entity.speakingQueue.shift();
            }
        }
    }
  
    static async loadSvgImage(svg: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            try {
                const tempDiv = document.createElement("div");
                tempDiv.innerHTML = svg.trim();
                const svgElement = tempDiv.querySelector('svg');
                
                if (!svgElement) {
                    throw new Error("Invalid SVG content");
                }

                // Ensure SVG has width and height
                if (!svgElement.hasAttribute('width') || !svgElement.hasAttribute('height')) {
                    console.warn("SVG element missing width or height attributes");
                }

                const svgString = new XMLSerializer().serializeToString(svgElement);
                const encodedSvg = encodeURIComponent(svgString);
                const dataUrl = `data:image/svg+xml,${encodedSvg}`;

                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = (e) => reject(new Error(`Failed to load SVG image: ${e}`));
                img.src = dataUrl;
            } catch (error) {
                reject(error);
            }
        });
    }
  
    addToDialogueHistory(message: string): void {
      // Create new dialogue entry with current timestamp
      const dialogue = new Dialogue(message, Date.now());
  
      // Add to history
      this.dialogueHistory.push(dialogue);
  
      // Calculate total length of dialogue history
      const totalLength = this.dialogueHistory.reduce(
        (sum, d) => sum + d.message.length,
        0
      );
  
      // Remove oldest entries if exceeding max length
      while (
        totalLength > this.maxHistoryLength &&
        this.dialogueHistory.length > 0
      ) {
        this.dialogueHistory.shift();
      }
  
      this.initiateDialogue();
      this.dialogueTimeAccumulator =
        (this.automation?.dialogue?.interval ?? DIALOGUE_COOLDOWN) -
        (Math.random() * RANDOM_DIALOGUE_TIME_VARIANCE + MINIMUM_DIALOGUE_DELAY);
    }
  
    // Add new method to handle dialogue initiation
    initiateDialogue(): void {
      this.hasInitiatedDialogue = true;
    }
  
    static calculateMessageDisplayTime(message: string): number {
      const wordsInMessage = message.length / 6;
      const millisecondsPerWord = (60 * 1000) / 150; // 400ms per word (150 wpm)
      return Math.max(
        2000,
        Math.min(wordsInMessage * millisecondsPerWord, 8000)
      );
    }
  
    checkIfGoodToSpeak(game: Game): boolean {
      const currentTime = Date.now();
      const lastDialogueEntry =
        this.dialogueHistory[this.dialogueHistory.length - 1];
      const timeSinceLastDialogue = lastDialogueEntry
        ? currentTime - lastDialogueEntry.timestamp
        : Infinity;
      const player = game.player;
      if (!player) {
        throw new Error("Player is required");
      }

      if (Date.now() - Entity.lastSpeakTimestamp < Entity.GLOBAL_SPEAK_COOLDOWN) {
        return false;
      }
  
      // Calculate minimum wait time based on last message length
      if (lastDialogueEntry) {
        const minimumWaitTime = Entity.calculateMessageDisplayTime(lastDialogueEntry.message);
        if (timeSinceLastDialogue < minimumWaitTime) {
          return false;
        }
      }
  
      // Check basic cooldown
      if (timeSinceLastDialogue < DIALOGUE_COOLDOWN) {
        return false;
      }
  
      if (this.dialogueTimeAccumulator < (this.automation?.dialogue?.interval ?? DIALOGUE_COOLDOWN)) {
        return false;
      }
  
      // Check if dialogue has been initiated (if required)
      if (!this.hasInitiatedDialogue) {
        return false;
      }
  
      return true;
    }
  
    // Add new inventory methods
    addToInventory(item: Entity): boolean {
      if (this.inventory.length >= this.maxInventorySize) {
        return false;
      }
      this.inventory.push(item);
      return true;
    }
  
    removeFromInventory(item: Entity): boolean {
      const index = this.inventory.indexOf(item);
      if (index > -1) {
        this.inventory.splice(index, 1);
        return true;
      }
      return false;
    }
  
    hasItem(itemId: string): boolean {
      return this.inventory.some((item) => item.id === itemId);
    }
  
    getInventoryContents(): Entity[] {
      return [...this.inventory];
    }
  
    canMoveAcrossTerrain(terrainType: { passable: boolean }): boolean {
      return terrainType && terrainType.passable;
    }
  }