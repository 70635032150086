import { authService } from '../contexts/AuthContext.ts';

const API_URL = process.env.API_URL || '/api';

export class PromptingService {
  static async getAuthHeaders() {
    const user = authService.getUser();
    if (!user) {
      throw new Error('User must be authenticated to use this service');
    }
    const token = await user.getIdToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
  }

  static async generateInteractions(entity, player, usingItem, lore) {
    try {
      console.group('Generate Interactions');
      console.log('Input:', { entity, player, usingItem, lore });
      
      const response = await fetch(`${API_URL}/prompting/interactions`, {
        method: "POST",
        headers: await this.getAuthHeaders(),
        body: JSON.stringify({ entity, player, usingItem, lore }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error === 'Token limit exceeded') {
          const error = new Error('Monthly token limit exceeded');
          error.name = 'TOKEN_LIMIT_EXCEEDED';
          throw error;
        }
        throw new Error(`API error: ${errorData.error || "Unknown error"}`);
      }

      const data = await response.json();
      console.log('Output:', data.actions);
      console.groupEnd();
      return data.actions;
    } catch (error) {
      console.groupEnd();
      console.error("Error generating interactions:", error);
      throw error;
    }
  }

  static async generateDialogue(entity, prompt, currentTime, lore = null) {
    try {
      console.group('Generate Dialogue');
      console.log('Input:', { entity, prompt, currentTime, lore });
      
      const response = await fetch(`${API_URL}/prompting/dialogue`, {
        method: "POST",
        headers: await this.getAuthHeaders(),
        body: JSON.stringify({ entity, prompt, currentTime, lore }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API error: ${errorData.error || "Unknown error"}`);
      }

      const data = await response.json();
      console.log('Output:', data.dialogue);
      console.groupEnd();
      return data.dialogue;
    } catch (error) {
      console.groupEnd();
      console.error("Error generating dialogue:", error);
      throw error;
    }
  }

  static async generateLoreAndTitle(messageLog, existingLore, currentLocation, newLocation) {
    try {
      console.group('Generate Lore and Title');
      console.log('Input:', { messageLog, existingLore, currentLocation, newLocation });
      
      const response = await fetch(`${API_URL}/prompting/lore-and-title`, {
        method: "POST",
        headers: await this.getAuthHeaders(),
        body: JSON.stringify({ 
          messageLog, 
          existingLore, 
          currentLocation, 
          newLocation 
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API error: ${errorData.error || "Unknown error"}`);
      }

      const data = await response.json();
      console.log('Output:', data);
      console.groupEnd();
      return data;
    } catch (error) {
      console.groupEnd();
      console.error("Error generating lore and title:", error);
      throw error;
    }
  }
} 

import { Entity } from "../../../shared/entities/Entity.ts";
Entity.setPromptingService(PromptingService);