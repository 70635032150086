// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.share-content {
  background: #1a1a1a;
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.share-link-container {
  display: flex;
  gap: 10px;
  margin: 15px 0;
}

.share-link-input {
  flex: 1;
  padding: 8px;
  background: #2d2d2d;
  border: 1px solid #444;
  color: white;
  border-radius: 4px;
}

.share-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.share-buttons button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background: #3498db;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.share-buttons button:hover {
  background: #2980b9;
  transform: translateY(-2px);
}

#copyShareLink {
  white-space: nowrap;
  min-width: 70px;
} `, "",{"version":3,"sources":["webpack://./client/src/styles/shareModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,OAAO;EACP,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".share-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.8);\n  display: none;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n}\n\n.share-content {\n  background: #1a1a1a;\n  color: white;\n  padding: 20px;\n  border-radius: 8px;\n  width: 90%;\n  max-width: 500px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);\n}\n\n.share-link-container {\n  display: flex;\n  gap: 10px;\n  margin: 15px 0;\n}\n\n.share-link-input {\n  flex: 1;\n  padding: 8px;\n  background: #2d2d2d;\n  border: 1px solid #444;\n  color: white;\n  border-radius: 4px;\n}\n\n.share-buttons {\n  display: flex;\n  justify-content: flex-end;\n  gap: 10px;\n  margin-top: 15px;\n}\n\n.share-buttons button {\n  padding: 8px 16px;\n  border-radius: 4px;\n  border: none;\n  background: #3498db;\n  color: white;\n  cursor: pointer;\n  transition: all 0.2s ease;\n}\n\n.share-buttons button:hover {\n  background: #2980b9;\n  transform: translateY(-2px);\n}\n\n#copyShareLink {\n  white-space: nowrap;\n  min-width: 70px;\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
