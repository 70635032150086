import { Entity, EntityData } from "@shared/entities/Entity";
import { GameRenderer } from "./GameRenderer.js";
import { PromptingService } from "./services/PromptingService.js";
import { FeedbackModal } from './components/FeedbackModal';
import { LogEntry } from '../../shared/LoreLog';
import { Orchestrator } from "./Orchestrator";
import { World } from "@shared/World";
import { Action, ActionResultExecution } from "@shared/types.js";
import { Player } from "@shared/entities/Player.js";

export class Game {
  terrainImages: { [key: string]: HTMLImageElement };
  entityImages: { [key: string]: HTMLImageElement };
  running: boolean;
  lastTickTime: number;
  tickInterval: NodeJS.Timeout | null;
  inventoryPanel: HTMLElement | null;
  renderer: GameRenderer;
  feedbackModal: FeedbackModal;
  messageLog: HTMLElement;
  buttonContainer: HTMLElement | null;
  world: World;
  orchestrator: Orchestrator;
  player: Player;
  saveButton: HTMLElement;
  feedbackButton: HTMLElement;
  touchControls: HTMLElement;
  musicAudioElement: HTMLAudioElement | null;
  pressedKeys: Set<string> = new Set<string>();
  animationFrameId: number | null = null;
  touchControlActive: boolean = false;

  constructor(renderer: GameRenderer, world: World, orchestrator: Orchestrator) {
    this.renderer = renderer;
    this.orchestrator = orchestrator;
    this.world = world;
    const player = this.world.getPlayer();
    if (!player) throw new Error("Player not found");
    this.player = player as Player;
    this.terrainImages = {};
    this.entityImages = {};
    this.musicAudioElement = null;
    this.running = false;
    this.lastTickTime = performance.now();
    this.tickInterval = null;
    this.inventoryPanel = document.getElementById("inventoryPanel");
    this.buttonContainer = document.getElementById("buttonContainer");
    if (!this.inventoryPanel) {
      throw new Error("Could not find inventory panel element");
    }

    // Create inventory slots
    for (let i = 0; i < (Number(process.env.INVENTORY_SLOTS) || 10); i++) {
      const slot = document.createElement("div");
      slot.className = "inventorySlot";
      this.inventoryPanel.appendChild(slot);
    }

    // Initialize feedback modal
    this.feedbackModal = new FeedbackModal(this);
    
    // Setup feedback button
    this.feedbackButton = document.getElementById('feedbackButton') || (() => { throw new Error("Feedback button not found") })();
    this.feedbackButton.addEventListener('click', () => {
      this.feedbackModal.show();
    });

    // Setup save button
    this.saveButton = document.getElementById('saveButton') || (() => { throw new Error("Save button not found") })();
    this.saveButton.addEventListener('click', () => {
        this.orchestrator.saveAndShare();
    });

    this.touchControls = document.getElementById('touchControls') || (() => { throw new Error("Touch controls not found") })();

    this.messageLog = document.getElementById("messageLog") || (() => { throw new Error("Could not find message log element") })();
    this.subscribeMessageLog();
  }

  async start() {
    await this.loadAllImages();
    this.setupControls();
    this.renderer.startGame(this);
    await this.resumeGame();
  }
  
  pauseGame() {
    this.hideGameUI();
    this.pauseGameLoop();
  }

  async resumeGame() {
    this.showGameUI();
    this.resumeGameLoop();
    // Snap camera to player position after level change
    if (this.world.level.musicUrl) {
      await this.playMusic(this.world.level.musicUrl);
    }
  }

  async switchWorlds(newWorld: World) {
    this.world = newWorld;
    const player = this.world.getPlayer();
    if (!player) throw new Error("Player not found");
    this.player = player as Player;
    
    // Resubscribe to the new world's lore log
    this.subscribeMessageLog();
    
    await this.loadAllImages();
  }

  subscribeMessageLog() {
    // Subscribe to log updates to update UI
    this.world.save.loreLog.subscribe((entry: LogEntry) => {
      if (!this.messageLog) throw new Error("Message log not found");
      
      const messageElement = document.createElement("div");
      messageElement.innerHTML = `<b>${entry.speaker}</b>: ${entry.content}`;
      this.messageLog.appendChild(messageElement);
      
      // Auto-scroll to bottom
      this.messageLog.scrollTop = this.messageLog.scrollHeight;
    });
  }

  async preloadTerrainImages() {
    const imagePromises = Object.entries(this.world.level.terrainTypes).map(
        async ([symbol, terrainType]) => {
            try {
                if (!terrainType.svg) {
                    console.warn(`No SVG defined for terrain type ${symbol}`);
                    return;
                }
                this.terrainImages[symbol] = await Entity.loadSvgImage(terrainType.svg);
                console.log(`Successfully loaded terrain image for ${symbol}`);
            } catch (error) {
                console.error(`Failed to load terrain image for ${symbol}:`, error);
                // Provide a fallback or placeholder image
                const fallbackSvg = `<svg width="32" height="32"><rect width="32" height="32" fill="${terrainType.color || '#FF0000'}"/></svg>`;
                this.terrainImages[symbol] = await Entity.loadSvgImage(fallbackSvg);
            }
        }
    );

    try {
        await Promise.all(imagePromises);
        console.log("Completed preloading terrain images");
    } catch (error) {
        console.error("Error in preloadTerrainImages:", error);
    }
  }

  async preloadEntityImages() {
    const processEntity = async (entity: Entity) => {
      if (entity.svg && !this.entityImages[entity.uniqueId]) {
        try {
          this.entityImages[entity.uniqueId] = await Entity.loadSvgImage(entity.svg);
        } catch (error) {
          console.warn(`Failed to load image for entity ${entity.uniqueId}:`, error);
        }
      }
      
      // Recursively process inventory items
      for (const item of entity.inventory || []) {
        await processEntity(item);
      }
    };

    // Process all placed entities and their inventories
    for (const entity of this.world.level.placedEntities) {
      await processEntity(entity);
    }
    if (!this.player) throw new Error("No player to load image");
    this.entityImages[this.player.uniqueId] = await this.player.loadImage();

    console.log("Preloaded entity images");
  }

  draw() {
    this.renderer.draw();
  }

  async generateLore(description: string, levelId: string) {
      // Increment level counter and handle lore generation
      this.world.save.levelChangeCount++;
      console.log("levelChangeCount:", this.world.save.levelChangeCount);
      if (this.world.save.levelChangeCount % 3 !== 0) return;
      
      const loreResult = await (async () => {
        const messageLogContent = Array.from(this.messageLog.children)
            .map(msg => msg.textContent)
            .join('\n');
        
        try {
            const { lore, title } = await PromptingService.generateLoreAndTitle(
                messageLogContent,
                this.player.lore || '',
                description,
                levelId
            );
            this.player.lore = lore;
            this.player.title = title;
            return true;
        } catch (error) {
            await this.orchestrator.handleError(error as Error);
            return false;
        }
    })();
    if (loreResult) {
      this.logMessage("Lore", `You are now known as: ${this.player.title}`, 'lore', 'lore');
      this.showPlayerModal();
    }
  }

  isValidMove(entity: Entity, newX: number, newY: number) {
    if (!this.world.level.terrain || !this.world.level.terrainTypes) {
        throw new Error("Terrain is required");
    }

    // Try the full movement first
    const fullMove = this.checkCollision(entity, newX, newY);
    if (fullMove.valid) {
        return fullMove;
    }

    // Try X movement only
    const xOnlyMove = this.checkCollision(entity, newX, entity.y);
    
    // Try Y movement only
    const yOnlyMove = this.checkCollision(entity, entity.x, newY);

    // If one axis is valid, use that
    if (xOnlyMove.valid && !yOnlyMove.valid) {
        return xOnlyMove;
    }
    if (yOnlyMove.valid && !xOnlyMove.valid) {
        return yOnlyMove;
    }

    // If neither axis works, find the closest valid position
    const dx = newX - entity.x;
    const dy = newY - entity.y;
    
    // Binary search for closest valid position
    let minT = 0;
    let maxT = 1;
    let bestT = 0;
    
    for (let i = 0; i < 8; i++) { // 8 iterations should give good precision
        const midT = (minT + maxT) / 2;
        const testX = entity.x + dx * midT;
        const testY = entity.y + dy * midT;
        
        if (this.checkCollision(entity, testX, testY).valid) {
            bestT = midT;
            minT = midT;
        } else {
            maxT = midT;
        }
    }

    // Use the best partial movement we found
    const finalX = entity.x + dx * bestT;
    const finalY = entity.y + dy * bestT;

    return {
        valid: true,
        validX: finalX,
        validY: finalY
    };
  }

  private checkCollision(entity: Entity, x: number, y: number) {
    // First check terrain collisions
    const oldCorners = [
        [Math.floor(entity.x + 0.05), Math.floor(entity.y + 0.05)],     // Top-left
        [Math.floor(entity.x + 0.94), Math.floor(entity.y + 0.05)],     // Top-right
        [Math.floor(entity.x + 0.05), Math.floor(entity.y + 0.94)],     // Bottom-left
        [Math.floor(entity.x + 0.94), Math.floor(entity.y + 0.94)]      // Bottom-right
    ];


    const corners = [
        [Math.floor(x + 0.05), Math.floor(y + 0.05)],           // Top-left
        [Math.floor(x + 0.94), Math.floor(y + 0.05)],           // Top-right
        [Math.floor(x + 0.05), Math.floor(y + 0.94)],           // Bottom-left
        [Math.floor(x + 0.94), Math.floor(y + 0.94)]            // Bottom-right
    ];

    for (const [checkX, checkY] of corners) {
        const terrain = this.world.level.terrain[checkY]?.[checkX];
        if (!terrain) {
            // Check if we're already colliding with out-of-bounds at this same position
            const isAlreadyCollidingHere = oldCorners.some(([oldX, oldY]) => 
                oldX === checkX && oldY === checkY && !this.world.level.terrain[oldY]?.[oldX]
            );
            if (!isAlreadyCollidingHere) {
                return {
                    valid: false,
                    validX: entity.x,
                    validY: entity.y
                };
            }
        }

        const terrainType = this.world.level.terrainTypes[terrain];
        if (terrainType?.passable === false) {
            // Check if we're already colliding with impassable terrain at this same position
            const isAlreadyCollidingHere = oldCorners.some(([oldX, oldY]) => {
                const oldTerrain = this.world.level.terrain[oldY]?.[oldX];
                return oldX === checkX && oldY === checkY && 
                        oldTerrain && this.world.level.terrainTypes[oldTerrain]?.passable === false;
            });
            if (!isAlreadyCollidingHere) {
                return {
                    valid: false,
                    validX: entity.x,
                    validY: entity.y
                };
            }
        }
    }
    

    // Then check entity collisions
    const ENTITY_RADIUS = 0.4; // Entities take up 80% of a tile's width
    const SQUARED_MIN_DISTANCE = (ENTITY_RADIUS * 2) * (ENTITY_RADIUS * 2); // Square the distance to avoid sqrt calculations

    for (const otherEntity of this.world.level.placedEntities) {
        // Skip self-collision and entities that are marked as non-colliding
        if (otherEntity === entity || otherEntity.blocking === false) {
            continue;
        }

        // Calculate squared distances for both current and proposed positions
        const currentDx = entity.x - otherEntity.x;
        const currentDy = entity.y - otherEntity.y;
        const currentSquaredDistance = currentDx * currentDx + currentDy * currentDy;

        const proposedDx = x - otherEntity.x;
        const proposedDy = y - otherEntity.y;
        const proposedSquaredDistance = proposedDx * proposedDx + proposedDy * proposedDy;

        // Only block movement if we're getting closer and would be too close
        if (proposedSquaredDistance < SQUARED_MIN_DISTANCE && 
            proposedSquaredDistance < currentSquaredDistance &&
            currentSquaredDistance > SQUARED_MIN_DISTANCE) {
            return {
                valid: false,
                validX: entity.x,
                validY: entity.y
            };
        }
    }

    // Also check collision with player if this isn't the player
    if (entity !== this.player) {
        const currentDx = entity.x - this.player.x;
        const currentDy = entity.y - this.player.y;
        const currentSquaredDistance = currentDx * currentDx + currentDy * currentDy;

        const proposedDx = x - this.player.x;
        const proposedDy = y - this.player.y;
        const proposedSquaredDistance = proposedDx * proposedDx + proposedDy * proposedDy;

        // Only block movement if we're getting closer and would be too close
        if (proposedSquaredDistance < SQUARED_MIN_DISTANCE && 
            proposedSquaredDistance < currentSquaredDistance) {
            return {
                valid: false,
                validX: entity.x,
                validY: entity.y
            };
        }
    }

    return {
        valid: true,
        validX: x,
        validY: y
    };
  }

  async playMusic(audioUrl: string) {
    try {
      console.log("Playing music from url:", audioUrl);
      
      // Stop any currently playing music
      if (this.musicAudioElement) {
        this.musicAudioElement.pause();
        this.musicAudioElement = null;
      }

      // Create and play new music
      this.musicAudioElement = new Audio(audioUrl);
      this.musicAudioElement.loop = true;
      await this.musicAudioElement.play();
    } catch (error) {
      console.error("Error playing music:", error);
    }
  }

  setupControls() {
    // Track currently pressed keys
    const pressedKeys = this.pressedKeys;

    document.addEventListener("keydown", (e) => {
        if (!this.running) return;
        const key = e.key.toLowerCase();
        pressedKeys.add(key);

        if (key === "t") {
            const message = prompt("What would you like to say?");
            if (message) {
                this.player.message = message;
                this.showMessage(this.player);
                this.logMessage("Player", message, this.player.uniqueId, 'dialogue');
            }
        }
    });

    document.addEventListener("keyup", (e) => {
        pressedKeys.delete(e.key.toLowerCase());
    });

    // Setup touch controls
    if ('ontouchstart' in window) {
        const controls = document.getElementById('touchControls');
        const joystickKnob = document.getElementById('joystickKnob');
        const joystickContainer = document.getElementById('joystickContainer');
        
        if (!controls || !joystickKnob || !joystickContainer) {
            throw new Error("Touch controls not found");
        }
        
        controls.classList.remove('hidden');

        let active = false;
        const joystickBounds = joystickContainer.getBoundingClientRect();
        const knobBounds = joystickKnob.getBoundingClientRect();
        const centerX = joystickBounds.width / 2;
        const centerY = joystickBounds.height / 2;
        const maxDistance = joystickBounds.width / 2 - knobBounds.width / 2; // Half container width minus half knob width

        // Center the joystick knob initially
        joystickKnob.style.transform = `translate(${- knobBounds.width / 2}px, ${- knobBounds.height / 2}px)`;

        const updateJoystickPosition = (clientX: number, clientY: number) => {
            const rect = joystickContainer.getBoundingClientRect();
            let dx = clientX - (rect.left + centerX);
            let dy = clientY - (rect.top + centerY);
            
            // Calculate distance from center
            const distance = Math.sqrt(dx * dx + dy * dy);
            
            // Normalize if distance is greater than maxDistance
            if (distance > maxDistance) {
                dx = (dx / distance) * maxDistance;
                dy = (dy / distance) * maxDistance;
            }

            // Update knob position relative to center
            joystickKnob.style.transform = `translate(${dx - knobBounds.width / 2}px, ${dy - knobBounds.height / 2}px)`;

            // Update player velocity (normalized between -1 and 1)
            this.player.velocityX = (dx / maxDistance) * this.player.moveSpeed;
            this.player.velocityY = (dy / maxDistance) * this.player.moveSpeed;
        };

        joystickContainer.addEventListener('touchstart', (e: TouchEvent) => {
            e.preventDefault();
            active = true;
            this.touchControlActive = true;
            const touch = e.touches[0];
            updateJoystickPosition(touch.clientX, touch.clientY);
        }, { passive: false });

        document.addEventListener('touchmove', (e: TouchEvent) => {
            if (!active) return;
            e.preventDefault();
            const touch = e.touches[0];
            updateJoystickPosition(touch.clientX, touch.clientY);
        }, { passive: false });

        const resetJoystick = () => {
            active = false;
            this.touchControlActive = false;
            joystickKnob.style.transform = `translate(${- knobBounds.width / 2}px, ${- knobBounds.height / 2}px)`;
            this.player.velocityX = 0;
            this.player.velocityY = 0;
        };

        document.addEventListener('touchend', resetJoystick);
        document.addEventListener('touchcancel', resetJoystick);

        // Setup talk button
        const talkButton = document.getElementById('talkButton');
        if (talkButton) {
            talkButton.addEventListener('touchstart', (e) => {
                e.preventDefault();
                const event = new KeyboardEvent('keydown', { key: 't' });
                document.dispatchEvent(event);
            });
        }
    }

    // Add touch support for entity interaction
    this.renderer.canvas.addEventListener('touchstart', (e: TouchEvent) => {
        e.preventDefault();
        const touch = e.touches[0];
        const simulatedClick = new MouseEvent('click', {
            clientX: touch.clientX,
            clientY: touch.clientY
        });
        this.renderer.canvas.dispatchEvent(simulatedClick);
    });
  }

  async handleSwitchLevels(switchEntity: Entity) {
      if (!switchEntity.switchLevels) {
        throw new Error("Switch entity has no switchLevels");
      }
      if (!this.player) {
        throw new Error("Player is required");
      }
      this.player.x = switchEntity.x;
      this.player.y = switchEntity.y;
      const switchLevels = switchEntity.switchLevels;
      console.log("switchLevels: ", switchLevels);
      const levelName = this.world.level.name;
      await Promise.all([
        this.generateLore(this.world.level.description, this.world.level.levelId),
        this.orchestrator.switchLevels(this.world, switchEntity)
      ]);
      const message = `You enter "${this.world.level.name}" from "${levelName}"`;
      this.logMessage("Lore", message, 'lore', 'lore');
      this.player.message = message;
      this.showMessage(this.player, true);

      return {
        success: true,
      };
  }

  startGameLoop() {
    this.running = true;
    this.lastTickTime = performance.now();
    this.animationFrameId = requestAnimationFrame(() => this.gameLoop());
  }

  pauseGameLoop() {
    console.log("Pausing game loop");
    this.running = false;
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  resumeGameLoop() {
    console.log("Resuming game loop");
    if (!this.running) {
      this.running = true;
      this.lastTickTime = performance.now();
      this.animationFrameId = requestAnimationFrame(() => this.gameLoop());
    }
  }

  private gameLoop() {
    if (!this.running) return;

    const currentTime = performance.now();
    const deltaTime = currentTime - this.lastTickTime;
    
    if (deltaTime >= 1) {
      this.lastTickTime = currentTime;
      this.tick(deltaTime);
    }

    this.animationFrameId = requestAnimationFrame(() => this.gameLoop());
  }

  private tick(deltaTime: number) {
    this.updatePlayerVelocityFromControls();

    try {
      // Update all entities
      for (const entity of this.world.level.placedEntities) {
        entity.tick(deltaTime, this);
      }
    } catch (error: any) {
      console.error("Error ticking:", error);
      this.orchestrator.handleError(error);
    }

    // Redraw the game
    this.renderer.draw();
  }

  // Update player velocity in the tick method
  updatePlayerVelocityFromControls() {
    const pressedKeys = this.pressedKeys;
    const player = this.player;
    let dx = 0;
    let dy = 0;

    // Only process keyboard input if touch controls aren't active
    if (!this.touchControlActive) {
        if (pressedKeys.has('w') || pressedKeys.has('arrowup')) dy -= 1;
        if (pressedKeys.has('s') || pressedKeys.has('arrowdown')) dy += 1;
        if (pressedKeys.has('a') || pressedKeys.has('arrowleft')) dx -= 1;
        if (pressedKeys.has('d') || pressedKeys.has('arrowright')) dx += 1;

        // Normalize diagonal movement
        if (dx !== 0 && dy !== 0) {
            const normalizer = 1 / Math.sqrt(2);
            dx *= normalizer;
            dy *= normalizer;
        }

        player.velocityX = dx * player.moveSpeed;
        player.velocityY = dy * player.moveSpeed;
    }
  }

  logMessage(displayName: string, message: string, speakerId: string, type: 'dialogue' | 'lore' | 'system') {
    const player = this.player;
    const entry = new LogEntry({
        type: type,
        speaker: displayName,
        content: message,
        metadata: { speakerId },
        timestamp: Date.now()
    });

    this.world.save.loreLog.addEntry(entry);

    // Add message to nearby entities' dialogue history
    if (type === 'dialogue') {
      const speakingEntity = this.world.level.placedEntities.find((e: Entity) => e.uniqueId === speakerId);
      if (!speakingEntity) {
        throw new Error("Speaking entity not found: " + speakerId);
      }
      const nearbyEntities = [...this.world.level.placedEntities].filter(
        (entity: Entity) =>
            entity !== speakingEntity &&
            Math.abs(entity.x - player.x) <= 4 &&
            Math.abs(entity.y - player.y) <= 4
      );
      console.log("Nearby entities: ", nearbyEntities); 
      // Add the message to their dialogue histories
      nearbyEntities.forEach((entity) => {
        entity.addToDialogueHistory(
            `${speakingEntity.uniqueId}|${displayName}: ${message}`
        );
      });
    } else if (type === 'lore') {
      // System message
      this.world.level.placedEntities.forEach((entity: Entity) => {
        entity.addToDialogueHistory(`Out-of-character Lore Event (from Player's perspective): ${message}`);
      });
    } else if (type === 'system') {
      // No entities will hear system messages
    }


  }

  showMessage(entity: Entity, narration = false) {
    this.renderer.showMessage(entity, narration);
  }



  

  // New helper methods
  async loadAllImages() {
    console.group("Loading all images... (including player)");
    this.renderer.pauseRendering();
    try {
        await Promise.all([
            this.preloadTerrainImages(),
            this.preloadEntityImages(),
        ]);
    } finally {
      this.renderer.resumeRendering();
      console.groupEnd();
    }
  }

  async handleEntityClick(entity: Entity, usingItem: Entity | null) {
    if (!this.running) return;

    const dx = Math.abs(this.player.x - entity.x);
    const dy = Math.abs(this.player.y - entity.y);
    if (dx > 1 || dy > 1) {
      this.logMessage("System", "You need to be closer to interact with that.", 'system', 'system');
      return;
    }

    // Special handling for switchLevel entities
    if (entity.switchLevels) {
      const actions: Action[] = [
        {
          name: "Enter " + entity.displayName,
          actionDescription: entity.description || "Enter.",
          results: [
            {
              type: 'switch_levels',
              switchLevels: entity.switchLevels
            }
          ],
          resultDescription: "",
        }
      ];
      this.renderer.showActionMenu(actions, entity);
      return;
    } else if (entity.id === 'player') {
      this.showPlayerModal();
      return;
    }

    try {
      this.renderer.spinners.showLoadingSpinner('Considering possible interactions...', 5000);
      this.pauseGameLoop();
      
      const actions = await PromptingService.generateInteractions(
        entity, 
        this.player, 
        usingItem,
        "This level's description: " + this.world.level.description
      );
      this.renderer.showActionMenu(actions, entity);
    } catch (error) {
      console.error("Error generating interactions:", error);
      await this.orchestrator.handleError(error as Error);
      this.resumeGameLoop();
    } finally {
      this.renderer.spinners.hideLoadingSpinner();
    }
  }

  async executeAction(action: Action, entity: Entity) {

    if (!action.results || action.results.length === 0) return;

    // Process each result in sequence
    for (const result of action.results) {
        try {
            let resultExecution: ActionResultExecution;
            
            switch (result.type) {
                case 'move':
                    if (!result.direction) {
                      throw new Error("Direction is required for move action");
                    }
                    const [dx, dy] = result.direction;
                    const newX = entity.x + dx;
                    const newY = entity.y + dy;
                    
                    const moveResult = this.isValidMove(entity, newX, newY);
                    if (!moveResult.valid) {
                        return { success: false, failureMessage: "" };
                    }
                    
                    entity.x = newX;
                    entity.y = newY;
                    this.renderer.draw();
                    resultExecution = { success: true };
                    break;
                    
                case 'give_new_item':
                    resultExecution = await this.handleGiveNewItem(result.item);
                    break;
                    
                case 'give_item_from_inventory':
                    if (!result.itemId) {
                      throw new Error("Item ID is required for give_item_from_inventory action");
                    }
                    resultExecution = await this.handleGiveItemFromInventory(entity, result.itemId);
                    break;
                    
                case 'take_item':
                    if (!result.itemId) {
                      throw new Error("Item ID is required for take_item action");
                    }
                    if (result.addToInventory === undefined) {
                      throw new Error("addToInventory is required for take_item action");
                    }
                    resultExecution = this.handleTakeItem(result.itemId, entity, result.addToInventory);
                    break;

                case 'give_this_to_player':
                    resultExecution = await this.handleGiveThisToPlayer(entity);
                    break;

                case 'remove_this':
                    resultExecution = this.handleRemoveThis(entity);
                    break;
                    
                case 'move_player_to_this':
                    resultExecution = await this.handleMovePlayerToThis(entity);
                    break;

                case 'change_this':
                    if (!result.entityChanges) {
                      throw new Error("Entity changes are required for change_this action");
                    }
                    resultExecution = await this.handleChangeThis(entity, result.entityChanges);
                    break;

                case 'switch_levels':
                    resultExecution = await this.handleSwitchLevels(entity);
                    break;

                case 'win_mini_achievement':
                    if (!result.achievementName) {
                      throw new Error("Achievement name is required for win_mini_achievement action");
                    }
                    resultExecution = await this.handleWinMiniAchievement(result.achievementName);
                    break;
                    
                default:
                    resultExecution = { 
                        success: false, 
                        failureMessage: `Unknown result type: ${result.type}` 
                    };
            }

            if (!resultExecution.success) {
              this.logMessage("System", resultExecution.failureMessage || "Unknown failure", 'system', 'system');
              return resultExecution;
            }
        } catch (error) {
            console.error(`Error processing action result:`, error);
            const failureMessage = "Something went wrong with that action.";
            this.logMessage("System", failureMessage, 'system', 'system');
            return { 
                success: false, 
                failureMessage 
            };
        }
    }

    // All results executed successfully
    if (action.resultDescription.length > 0) {
      this.logMessage("Lore", action.resultDescription, 'lore', 'lore');
      this.player.message = action.resultDescription;
      this.showMessage(this.player, true);
    }
    return { success: true };
}

async handleWinMiniAchievement(achievementName: string) {
  this.player.completedAchievements.add(achievementName);
  this.logMessage("Lore", `Achievement unlocked: ${achievementName}`, 'lore', 'lore');
  
  return { success: true };
}

async handleGiveNewItem(itemData: EntityData): Promise<ActionResultExecution> {
    const player = this.player;
    const itemEntity = new Entity(itemData, player.x, player.y);
    
    if (this.player.addToInventory(itemEntity)) {
        await Entity.loadSvgImage(itemData.svg)
            .then(img => this.entityImages[itemEntity.uniqueId] = img);
        this.renderer.updateInventoryDisplay();
        this.logMessage("Lore", `You received: ${itemEntity.displayName}`, 'lore', 'lore');
        return { success: true };
    }
    return { 
        success: false, 
        failureMessage: "Your inventory is full!" 
    };
}

async handleGiveItemFromInventory(entity: Entity, itemId: string) {
    // Helper function to find item and its container recursively
    const findItemAndContainer = (container: Entity): [Entity | null, Entity | null] => {
        // Check direct inventory
        const directItem = container.inventory?.find(item => item.uniqueId === itemId);
        if (directItem) {
            return [directItem, container];
        }

        // Check nested inventories
        for (const item of container.inventory || []) {
            const [nestedItem, nestedContainer] = findItemAndContainer(item);
            if (nestedItem) {
                return [nestedItem, nestedContainer];
            }
        }

        return [null, null];
    };

    const [foundItem, container] = findItemAndContainer(entity);
    
    if (!foundItem || !container) {
        return { 
            success: false, 
            failureMessage: "That item is no longer available." 
        };
    }
    
    if (this.player.addToInventory(foundItem)) {
        // Remove item from its container's inventory
        const containerIndex = container.inventory?.findIndex((item: Entity) => item.uniqueId === foundItem.uniqueId);
        if (containerIndex !== undefined && containerIndex !== -1 && container.inventory) {
            container.inventory.splice(containerIndex, 1);
        }
        
        if (foundItem.svg && !this.entityImages[foundItem.id]) {
            this.entityImages[foundItem.id] = await Entity.loadSvgImage(foundItem.svg);
        }
        
        this.renderer.updateInventoryDisplay();
        this.logMessage("Lore", `You received: ${foundItem.displayName}`, 'lore', 'lore');
        return { success: true };
    }
    
    return { 
        success: false, 
        failureMessage: "Your inventory is full!" 
    };
}

handleTakeItem(itemId: string, entityTaking: Entity, addToInventory: boolean) {
    const player = this.player;
    const itemIndex = player.inventory.findIndex((item: Entity) => item.uniqueId === itemId);
    const item = player.inventory[itemIndex];
    
    if (itemIndex === -1) {
        return { 
            success: false, 
            failureMessage: "You don't have that item." 
        };
    }
    
    this.player.inventory.splice(itemIndex, 1);
    if (addToInventory) {
      entityTaking.inventory.push(item);
    }
    this.renderer.updateInventoryDisplay();
    return { success: true };
}

async handleGiveThisToPlayer(entity: Entity) {
    const player = this.player;
    const entityIndex = this.world.level.placedEntities.findIndex((e: Entity) => e === entity);
    if (entityIndex === -1) {
        return { 
            success: false, 
            failureMessage: "That entity no longer exists." 
        };
    }

    if (player.addToInventory(entity)) {
      this.world.level.placedEntities.splice(entityIndex, 1);
    } else {
      return {
        success: false,
        failureMessage: "Your inventory is full!"
      };
    }
    
    this.renderer.draw();
    return { success: true };
}

handleRemoveThis(entity: Entity) {
    const entityIndex = this.world.level.placedEntities.findIndex((e: Entity) => e === entity);
    if (entityIndex === -1) {
        return { 
            success: false, 
            failureMessage: "That entity no longer exists." 
        };
    }
    
    this.world.level.placedEntities.splice(entityIndex, 1);
    this.renderer.draw();
    return { success: true };
}

async handleMovePlayerToThis(entity: Entity) {
    const player = this.player;
    const entityIndex = this.world.level.placedEntities.findIndex((e: Entity) => e === entity);
    if (entityIndex === -1) {
        return { 
            success: false, 
            failureMessage: "That entity no longer exists." 
        };
    }

    player.x = entity.x;
    player.y = entity.y;

    this.renderer.draw();
    return { success: true };
}

async handleChangeThis(entity: Entity, entityChanges: Partial<EntityData>) {
    // Helper function for deep merging objects with null/undefined checks
    const deepMerge = (target: any, source: any) => {
        if (!source) return target;
        
        for (const key in source) {
            if (!source.hasOwnProperty(key)) continue;
            
            // If target doesn't have the property, create it
            if (!(key in target)) {
                target[key] = {};
            }
            
            if (source[key] instanceof Object && !Array.isArray(source[key])) {
                // For objects (not arrays), recursively merge
                target[key] = deepMerge(target[key] || {}, source[key]);
            } else {
                // For primitives and arrays, simply assign
                target[key] = source[key];
            }
        }
        return target;
    };

    // Create a copy of the entity's data before merging
    const entityData = { ...entity };
    
    // Deep merge the changes
    deepMerge(entityData, entityChanges);
    
    // Safely apply the merged changes back to the entity
    Object.assign(entity, entityData);

    // Update the entity's image if provided
    if (entityChanges.svg) {
        this.entityImages[entity.uniqueId] = await Entity.loadSvgImage(entityChanges.svg);
    }

    if (entityChanges.names) {
        entity.name = entityChanges.names[Math.floor(Math.random() * entityChanges.names.length)];
    }

    this.renderer.draw();
    return { success: true };
}



  // Add new helper methods to handle UI visibility
  hideGameUI() {
    if (this.messageLog) this.messageLog.classList.add('hidden');
    if (this.inventoryPanel) this.inventoryPanel.classList.add('hidden');
    if (this.buttonContainer) this.buttonContainer.classList.add('hidden');
    
    // Hide additional buttons
    this.saveButton.classList.add('hidden');
    this.feedbackButton.classList.add('hidden');
    this.touchControls.classList.add('hidden');
  }

  showGameUI() {
    if (this.messageLog) this.messageLog.classList.remove('hidden');
    if (this.inventoryPanel) this.inventoryPanel.classList.remove('hidden');
    if (this.buttonContainer) this.buttonContainer.classList.remove('hidden');
    
    // Show additional buttons
    this.saveButton.classList.remove('hidden');
    this.feedbackButton.classList.remove('hidden');
    if ('ontouchstart' in window) this.touchControls.classList.remove('hidden');
  }

  dropItemAtLocation(item: Entity, x: number, y: number) {
    const player = this.player;
    // Remove item from player's inventory
    const itemIndex = player.inventory.findIndex((i: Entity) => i.uniqueId === item.uniqueId);
    if (itemIndex === -1) return;
    
    const droppedItem = player.inventory.splice(itemIndex, 1)[0];
    
    droppedItem.x = x;
    droppedItem.y = y;
    
    // Add to placed entities
    this.world.level.placedEntities.push(droppedItem);
    
    // Update displays
    this.renderer.updateInventoryDisplay();
    this.renderer.draw();
    
    // Log the action
    this.logMessage("Lore", `You dropped ${droppedItem.displayName}`, 'lore', 'lore');
  }

  showPlayerModal() {
    const modal = document.getElementById('playerModal');
    if (!modal) {
      throw new Error("Player modal not found");
    }
    const player = this.player;

    // Calculate total available achievements
    const completedAchievementsCount = player.completedAchievements.size;
    
    modal.innerHTML = `
        <div class="player-modal-section">
            <h2>${player.title || 'Unnamed Adventurer'}</h2>
            <p>${player.lore || 'Your story is yet to be written...'}</p>
        </div>
        <div class="player-modal-section">
            <h3>World Map</h3>
            <canvas id="levelGraph" width="600" height="600"></canvas>
        </div>
        <div class="player-modal-section">
            <h3>Achievements Completed</h3>
            <div class="achievement-count">${completedAchievementsCount} achievements</div>
            <ul class="achievement-list">
                ${Array.from(player.completedAchievements)
                    .map(achievement => `<li>${achievement}</li>`)
                    .join('')}
            </ul>
        </div>
        <p><i>Click anywhere to dismiss</i></p>
    `;
    
    // Draw the level graph
    const canvas = document.getElementById('levelGraph') as HTMLCanvasElement;
    if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
            const levels = this.world.save.loreLog.worldBuilding.levels;
            const connections = this.world.save.loreLog.worldBuilding.connections;
            
            // Calculate connectivity for each level
            const connectivity = new Map<string, number>();
            levels.forEach(level => connectivity.set(level.uid, 0));
            connections.forEach(conn => {
                connectivity.set(conn.fromId, (connectivity.get(conn.fromId) || 0) + 1);
                connectivity.set(conn.toId, (connectivity.get(conn.toId) || 0) + 1);
            });

            // Sort levels by connectivity (most connected first)
            const sortedLevels = [...levels].sort((a, b) => 
                (connectivity.get(b.uid) || 0) - (connectivity.get(a.uid) || 0)
            );

            // Calculate positions using a modified spiral layout
            const nodePositions = new Map<string, {x: number, y: number}>();
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const baseRadius = canvas.width / 6; // Smaller initial radius for more compact center

            sortedLevels.forEach((level, index) => {
                // More connected nodes will have smaller indices and be closer to center
                const connectionCount = connectivity.get(level.uid) || 0;
                const radiusMultiplier = 1 + (index / levels.length); // Gradually increase radius
                const radius = baseRadius * radiusMultiplier;
                
                // Golden angle for better distribution
                const goldenAngle = Math.PI * (3 - Math.sqrt(5));
                const angle = index * goldenAngle;

                nodePositions.set(level.uid, {
                    x: centerX + radius * Math.cos(angle),
                    y: centerY + radius * Math.sin(angle)
                });
            });

            // Draw connections with opacity based on distance from center
            ctx.lineWidth = 1;
            connections.forEach(conn => {
                const fromPos = nodePositions.get(conn.fromId);
                const toPos = nodePositions.get(conn.toId);
                if (fromPos && toPos) {
                    const fromConnections = connectivity.get(conn.fromId) || 0;
                    const toConnections = connectivity.get(conn.toId) || 0;
                    const avgConnectivity = (fromConnections + toConnections) / 2;
                    const maxConnectivity = Math.max(...Array.from(connectivity.values()));
                    
                    // Higher opacity for connections between more connected nodes
                    const opacity = 0.3 + (0.7 * (avgConnectivity / maxConnectivity));
                    
                    ctx.beginPath();
                    ctx.strokeStyle = `rgba(102, 102, 102, ${opacity})`;
                    ctx.moveTo(fromPos.x, fromPos.y);
                    ctx.lineTo(toPos.x, toPos.y);
                    ctx.stroke();
                }
            });

            // Draw nodes with size based on connectivity
            sortedLevels.forEach((level) => {
                const pos = nodePositions.get(level.uid);
                if (pos) {
                    const connections = connectivity.get(level.uid) || 0;
                    const maxConnections = Math.max(...Array.from(connectivity.values()));
                    const minRadius = 5;
                    const maxRadius = 15;
                    const radius = minRadius + (maxRadius - minRadius) * (connections / maxConnections);

                    // Draw node
                    ctx.beginPath();
                    ctx.fillStyle = level.uid === this.world.level.levelId ? '#4CAF50' : '#2196F3';
                    ctx.arc(pos.x, pos.y, radius, 0, 2 * Math.PI);
                    ctx.fill();

                    // Draw label with size based on connectivity
                    const fontSize = 10 + (2 * (connections / maxConnections));
                    ctx.fillStyle = '#FFF';
                    ctx.font = `${fontSize}px Arial`;
                    ctx.textAlign = 'center';
                    ctx.fillText(level.seen ? level.name : '?', pos.x, pos.y + radius + 10);
                }
            });
        }
    }
    
    modal.classList.remove('hidden');
    
    const hideModal = () => {
        modal.classList.add('hidden');
        modal.removeEventListener('click', hideModal);
    };
    
    modal.addEventListener('click', hideModal);
  }


}
