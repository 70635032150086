export class Spinners {
    showLoadingSpinner(message = 'Loading...', expectedDuration = 2000) {
        const spinner = document.getElementById("loading-spinner");
        const loadingText = document.getElementById("loading-text");
        if (!loadingText) {
          throw new Error("Loading text not found");
        }
        const progressBar = spinner?.querySelector(".progress-bar-fill");
        if (!progressBar || !(progressBar instanceof HTMLElement)) {
          throw new Error("Progress bar not found");
        }
        
        if (spinner) {
          loadingText.textContent = message;
          spinner.classList.remove('hidden');
          
          if (progressBar) {
            progressBar.style.width = '0%';
            
            // Animate progress bar
            let startTime = Date.now();
            const updateProgress = () => {
              const elapsed = Date.now() - startTime;
              const progress = Math.min((elapsed / expectedDuration) * 100, 99);
              progressBar.style.width = `${progress}%`;
              
              if (elapsed < expectedDuration && !spinner.classList.contains('hidden')) {
                requestAnimationFrame(updateProgress);
              }
            };
            
            requestAnimationFrame(updateProgress);
          }
        }
      }
    
      hideLoadingSpinner() {
        const spinner = document.getElementById("loading-spinner");
        const progressBar = spinner?.querySelector(".progress-bar-fill");
        if (!progressBar || !(progressBar instanceof HTMLElement)) {
          throw new Error("Progress bar not found");
        }
        if (spinner) {
          if (progressBar) {
            progressBar.style.width = '100%';
            // Brief delay to show completion before hiding
            setTimeout(() => {
              spinner.classList.add('hidden');
              progressBar.style.width = '0%';
            }, 200);
          } else {
            spinner.classList.add('hidden');
          }
        }
      }
}