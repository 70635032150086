import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAE8uScp2A9Cef4XUQdFPitEJiQBZ3n79o",
    authDomain: "dream-rogue.firebaseapp.com",
    projectId: "dream-rogue",
    storageBucket: "dream-rogue.firebasestorage.app",
    messagingSenderId: "706723097090",
    appId: "1:706723097090:web:d12b94b24b0df2b532951b",
    measurementId: "G-7754Y0ZW3T"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

// Helper functions
export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};

export const signOut = () => auth.signOut(); 