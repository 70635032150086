// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.feedback-content {
  background: #1a1a1a;
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.feedback-content textarea {
  width: 100%;
  min-height: 100px;
  margin: 10px 0;
  padding: 8px;
  background: #2d2d2d;
  border: 1px solid #444;
  color: white;
  border-radius: 4px;
}

.feedback-buttons button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background: #3498db;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.feedback-buttons button:hover {
  background: #2980b9;
  transform: translateY(-2px);
}

.feedback-note {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 10px;
  line-height: 1.4;
} `, "",{"version":3,"sources":["webpack://./client/src/styles/feedback.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".feedback-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.8);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n}\n\n.feedback-content {\n  background: #1a1a1a;\n  color: white;\n  padding: 20px;\n  border-radius: 8px;\n  width: 90%;\n  max-width: 500px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);\n}\n\n.feedback-content textarea {\n  width: 100%;\n  min-height: 100px;\n  margin: 10px 0;\n  padding: 8px;\n  background: #2d2d2d;\n  border: 1px solid #444;\n  color: white;\n  border-radius: 4px;\n}\n\n.feedback-buttons button {\n  padding: 8px 16px;\n  border-radius: 4px;\n  border: none;\n  background: #3498db;\n  color: white;\n  cursor: pointer;\n  transition: all 0.2s ease;\n}\n\n.feedback-buttons button:hover {\n  background: #2980b9;\n  transform: translateY(-2px);\n}\n\n.feedback-note {\n  font-size: 0.9em;\n  color: #888;\n  margin-bottom: 10px;\n  line-height: 1.4;\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
