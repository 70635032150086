import { Entity, SwitchLevels } from '@shared/entities/Entity';
import { authService } from '../contexts/AuthContext';
import { World } from "@shared/World";
import { worldgen } from '../fixtures/worldgen';

export class WorldService {

  static async generateWorld(description: string) {
    // Check for fixture mode
    if (process.env.USE_FIXTURES === 'true') {
      console.log('Using fixture data for world generation');
      return World.fromJSON(worldgen);
    }

    try {
      // Get the current user's ID token
      const user = authService.getUser();
      const idToken = user ? await user.getIdToken() : null;

      const response = await fetch("/api/worlds/generate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(idToken && { Authorization: `Bearer ${idToken}` }),
        },
        body: JSON.stringify({
          description
        }),
      });

      await this.handleError(response);

      const data = await response.json();
      console.log('WorldGeneration Response:', data);
      const worldData = World.fromJSON(data);
      return worldData;

    } catch (error) {
      throw error;
    }
  }
  static async saveWorld(world: World) {
    try {
      // Get the current user's ID token
      const user = authService.getUser();
      const idToken = user ? await user.getIdToken() : null;

      const response = await fetch("/api/worlds/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(idToken && { Authorization: `Bearer ${idToken}` }),
        },
        body: JSON.stringify(world),
      });

      await WorldService.handleError(response);

      const data = await response.json();
      const shortCode = data.shortCode;
      console.log('WorldSave Response:', data);
      return shortCode;

    } catch (error) {
      throw error;
    }
  }
  static async switchLevels(world: World, switchEntity: Entity) {
    // Check for fixture mode
    if (process.env.USE_FIXTURES === 'true') {
      console.log('Using fixture data for level switching');
      return World.fromJSON(worldgen);
    }

    try {
      if (!switchEntity.switchLevels) {
        throw new Error("Switch entity does not have switchLevels");
      }
      // Get the current user's ID token
      const user = authService.getUser();
      const idToken = user ? await user.getIdToken() : null;

      const response = await fetch("/api/worlds/switchLevels", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(idToken && { Authorization: `Bearer ${idToken}` }),
        },
        body: JSON.stringify({
          world,
          switchEntity
        }),
      });

      await WorldService.handleError(response);

      const data = await response.json();
      console.log('WorldSwitchLevelss Response:', data);
      return World.fromJSON(data);

    } catch (error) {
      throw error;
    }
  }
  static async loadWorld(worldId: string) {
    // Check for fixture mode
    if (process.env.USE_FIXTURES === 'true') {
      console.log('Using fixture data for world loading');
      return World.fromJSON(worldgen);
    }

    try {
      // Get the current user's ID token
      const user = authService.getUser();
      const idToken = user ? await user.getIdToken() : null;

      const response = await fetch("/api/worlds/load", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(idToken && { Authorization: `Bearer ${idToken}` }),
        },
        body: JSON.stringify({
          worldId
        }),
      });
      await WorldService.handleError(response);


      const data = await response.json();
      console.log('WorldLoad Response:', data);
      const worldData = World.fromJSON(data);
      return worldData;

    } catch (error) {
      throw error;
    }
  }
  static async handleError(response: Response) {
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === 'Token limit exceeded') {
        const error = new Error('Monthly token limit exceeded');
        error.name = 'TOKEN_LIMIT_EXCEEDED';
        throw error;
      }
      throw new Error(`API error: ${errorData.error || "Unknown error"}`);
    }
  }
} 