import { Level } from "@shared/Level";
import { Save } from "@shared/Save";
import { Player } from "./entities/Player";

export class World {
  id: string;
  level: Level;
  save: Save;
  constructor(id: string, level: Level, save: Save) {
    this.id = id;
    this.level = level;
    this.save = save;
  }

  toJSON() {
    return {
      id: this.id,
      level: this.level,
      save: this.save
    };
  }

  getPlayer(){
    return this.level.placedEntities.find(e => e.uniqueId === this.save.playerId) as Player;
  }

  static fromJSON(jsonObject: any) {
    if (!jsonObject || typeof jsonObject !== 'object') {
      throw new Error('Invalid world data: must be an object');
    }

    if (!jsonObject.id || typeof jsonObject.id !== 'string') {
      throw new Error('Invalid world data: missing or invalid id');
    }

    if (!jsonObject.level || typeof jsonObject.level !== 'object') {
      throw new Error('Invalid world data: missing or invalid level');
    }

    if (!jsonObject.save || typeof jsonObject.save !== 'object') {
      throw new Error('Invalid world data: missing or invalid save');
    }
    return new World(jsonObject.id, Level.fromJSON(jsonObject.level), Save.fromJSON(jsonObject.save));
  }
}