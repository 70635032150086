import { start } from "repl";
import { auth, signInWithGoogle, signOut } from "./config/firebase";
import { Orchestrator } from "./Orchestrator";

document.addEventListener("DOMContentLoaded", () => {
  const canvas = document.getElementById("gameCanvas") as HTMLCanvasElement;
  const startButton = document.getElementById("startButton") as HTMLButtonElement;
  const startScreen = document.getElementById("startScreen") as HTMLDivElement;
  const gameContainer = document.getElementById("gameContainer") as HTMLDivElement;
  const worldDescription = document.getElementById("worldDescription") as HTMLInputElement;
  const loadButton = document.getElementById("loadButton") as HTMLButtonElement;
  const loginButton = document.getElementById("loginButton") as HTMLButtonElement;
  const logoutButton = document.getElementById("logoutButton") as HTMLButtonElement;
  const userInfo = document.getElementById("userInfo") as HTMLDivElement;
  const userName = document.getElementById("userName") as HTMLSpanElement;


  if (!canvas || !startButton || !startScreen || !gameContainer || !worldDescription || !loadButton || !loginButton || !logoutButton || !userInfo || !userName) {
    throw new Error("Required DOM elements not found: " + 
      [
        !canvas && "canvas",
        !startButton && "startButton", 
        !startScreen && "startScreen",
        !gameContainer && "gameContainer", 
        !worldDescription && "worldDescription",
        !loadButton && "loadButton",
        !loginButton && "loginButton",
        !logoutButton && "logoutButton",
        !userInfo && "userInfo",
        !userName && "userName"
      ].filter(Boolean).join(", "));
  }

  const urlParams = new URLSearchParams(window.location.search);
  const loadId = urlParams.get('load');

  if (loadId) {
    worldDescription.classList.add('hidden');
    const descriptionInstructions = worldDescription.previousElementSibling;
    if (descriptionInstructions && descriptionInstructions.tagName === 'P') {
      descriptionInstructions.classList.add('hidden');
    }
  }

  const orchestrator = new Orchestrator(canvas, localStorage);

  // Handle start button click
  startButton.addEventListener("click", async () => {
    try {
      showGameUI();

      if (loadId) {
        orchestrator.startSavedWorld(loadId);
      } else {
        // Get description with fallback to default
        // Get description with fallback to default
        const description = worldDescription.value.trim();

        // Initialize game with description
        await orchestrator.startNewWorld(description);
      }
    } catch (error) {
      console.error("Failed to initialize game:", error);
    }
  });

  function showGameUI(){
    startScreen.classList.add('hidden');
    gameContainer.classList.remove('hidden');
  }

  loadButton.addEventListener("click", async () => {
    try {
      showGameUI();
      const saveId = localStorage.getItem("saveId");
      if (saveId) {
        orchestrator.startSavedWorld(saveId);
      } else {
        alert("No saved game found!");
      }
    } catch (error) {
      console.error("Failed to load game:", error);
    }
  });


  // Auth state observer
  loginButton.classList.add('hidden');
  auth.onAuthStateChanged(async (user) => {
    console.log(user);
    if (user) {
      // User is signed in
      loginButton.classList.add('hidden');
      userInfo.classList.remove('hidden');
      userName.textContent = user.email;
      
      // Check for load parameter
      if (loadId) {
        startButton.classList.remove('hidden');
        startButton.textContent = "Continue to Game";
      } else {
        // Show authenticated buttons
        startButton.classList.remove('hidden');
        loadButton.classList.remove('hidden');
      }
    } else {
      // User is signed out
      loginButton.classList.remove('hidden');
      userInfo.classList.add('hidden');
      userName.textContent = '';
      
      // Hide authenticated buttons
      startButton.classList.add('hidden');
      loadButton.classList.add('hidden');
    }
  });

  // Auth event listeners
  loginButton.addEventListener("click", async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error("Error signing in:", error);
      alert("Failed to sign in");
    }
  });

  logoutButton.addEventListener("click", async () => {
    try {
      await signOut();
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Failed to sign out");
    }
  });

});
