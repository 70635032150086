import { Player } from "@shared/entities/Player";
import { LoreLog } from "@shared/LoreLog";

export class Save {
  levelId: string;
  loreLog: LoreLog;
  levelChangeCount: number;
  playerId: string;

  constructor({levelId, loreLog, levelChangeCount, playerId}: {levelId: string, loreLog: LoreLog, levelChangeCount: number, playerId: string}) {
    this.levelId = levelId;
    this.loreLog = loreLog;
    this.levelChangeCount = levelChangeCount;
    this.playerId = playerId;
  }

  toJSON() {
    return {
      levelId: this.levelId,
      loreLog: this.loreLog,
      levelChangeCount: this.levelChangeCount,
      playerId: this.playerId
    };
  }

  static fromJSON(data: any) {
    const loreLog = LoreLog.fromJSON(data.loreLog);
    return new Save({levelId: data.levelId, loreLog, levelChangeCount: data.levelChangeCount, playerId: data.playerId});
  }
}