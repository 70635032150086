import { WorldService } from "./services/WorldService";
import { Game } from "./Game";
import { GameRenderer } from "./GameRenderer";
import { World } from "@shared/World";
import { authService } from './contexts/AuthContext';
import { ShareModal } from "./components/ShareModal";
import { Spinners } from "./components/Spinners";
import { Entity, SwitchLevels } from "@shared/entities/Entity";
import { ErrorModal } from './components/ErrorModal';

export class Orchestrator {
    game: Game | null = null;
    renderer: GameRenderer | null = null;
    spinners: Spinners = new Spinners();
    shareModal: ShareModal = new ShareModal();
    localStorage: Storage;
    canvas: HTMLCanvasElement;
    private errorModal: ErrorModal;
    constructor(canvas: HTMLCanvasElement, localStorage: Storage) {
        this.canvas = canvas;
        this.localStorage = localStorage;
        this.errorModal = new ErrorModal();
    }
    async startNewWorld(description: string) {
        try {
          // Hide UI elements initially
          if (!description) {
            throw new Error("Description is required");
          }
          this.spinners.showLoadingSpinner('Dreaming...\n\n(90 seconds average, 10 minutes cold start)', 90000);
          const world = await WorldService.generateWorld(description);
          console.log('world', world);
          this.renderer = new GameRenderer(this.canvas, world, this);
          this.game = new Game(this.renderer, world, this);
          await this.game.start();
          this.spinners.hideLoadingSpinner();
          await this.saveWorld(world);
        } catch (error) {
          await this.handleError(error as Error);
        } finally {
          this.spinners.hideLoadingSpinner();
        }
      }
      async startSavedWorld(saveId: string) {
        try {
          // Hide UI elements initially
          await this.game?.pauseGame();
          if (!saveId) {
            throw new Error("Save ID is required");
          }
          const url = new URL(window.location.href);
          url.searchParams.set('load', saveId);
          window.history.replaceState({}, '', url.toString());
          this.spinners.showLoadingSpinner('Dreaming...', 5000);
          const world = await WorldService.loadWorld(saveId);
          this.spinners.hideLoadingSpinner();
          this.renderer = new GameRenderer(this.canvas, world, this);
          this.game = new Game(this.renderer, world, this);
          await this.game.start();
        } catch (error) {
          await this.handleError(error as Error);
        } finally {
          this.spinners.hideLoadingSpinner();
        }
      }
      async switchLevels(world: World, switchEntity: Entity) {
        this.game?.pauseGame();
        this.spinners.showLoadingSpinner('Dreaming...\n\n(90 seconds average, 10 minutes cold start)', 90000);
        const newWorld = await WorldService.switchLevels(world, switchEntity);
        this.spinners.hideLoadingSpinner();
        await this.game?.switchWorlds(newWorld);
        await this.renderer?.switchWorlds(newWorld);
        await this.game?.resumeGame();
        await this.saveWorld(newWorld);
        return newWorld;
      }

      async saveWorld(world: World){
        // Update URL with new save ID without reloading page
        const shortId = await WorldService.saveWorld(world);
        const url = new URL(window.location.href);
        url.searchParams.set('load', shortId);
        window.history.replaceState({}, '', url.toString());
        this.localStorage.setItem('saveId', shortId);
        return shortId;
      }

      async handleError(error: Error) {
        console.error('Game error:', error);
        
        if (error?.name === 'TOKEN_LIMIT_EXCEEDED') {
          this.errorModal.show(
            'Token Limit Exceeded',
            'You have reached your monthly AI token limit. Please try again next month or contact support for more tokens.'
          );
        } else {
          this.errorModal.show(
            'Error',
            'An unexpected error occurred. Please try again later.'
          );
          console.log('Error:', error);
        }
      }
      async saveAndShare() {
        try {
          if (!this.game?.world) {
            throw new Error('No game to save');
          }
          // Check if user is logged in first
          if (!authService.getUser()) {
            this.renderer?.showError('System', 'Please log in to save your game');
            return;
          }
    
          this.spinners.showLoadingSpinner('Saving game...', 5000);
          const shortId = await this.saveWorld(this.game.world);
          this.shareModal.show(shortId);
          this.localStorage.setItem('saveId', shortId);
        } catch (error) {
          console.error('Error saving game:', error);
          this.errorModal.show(
            'System', 
            error instanceof Error ? error.message : 'Failed to save game'
          );
        } finally {
          this.spinners.hideLoadingSpinner();
        }
      }
}