import { Entity } from "@shared/entities/Entity";
import { Spinners } from "./components/Spinners";
import { Orchestrator } from "./Orchestrator";
import { Game } from "./Game";
import { World } from "@shared/World";
import { Player } from "@shared/entities/Player";
import { Action } from "@shared/types";
import { ErrorModal } from './components/ErrorModal';

export class GameRenderer {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  spinners: Spinners;
  orchestrator: Orchestrator;
  zoomLevel: number;
  cameraX: number;
  cameraY: number;
  targetCameraX: number;
  targetCameraY: number;
  cameraSmoothing: number;
  chatBubblesContainer: HTMLDivElement;
  selectedInventoryItem: { item: Entity, index: number } | null;
  renderingPaused: boolean;
  fogData: ImageData | null;
  discoveredAreas: Uint8ClampedArray | null;
  world: World;
  player: Player;
  tooltipCanvas: HTMLCanvasElement;
  tooltipCtx: CanvasRenderingContext2D;
  bubbles: { [key: string]: { bubble: HTMLDivElement, timeout: NodeJS.Timeout | null } };
  game: Game | null;
  private errorModal: ErrorModal;

  constructor(canvas: HTMLCanvasElement, world: World, orchestrator: Orchestrator) {
    this.canvas = canvas;
    this.context = canvas.getContext("2d") || (() => { throw new Error("Could not get canvas context") })();
    this.world = world;
    const player = world.getPlayer();
    if (!player) {
      throw new Error("Player not found");
    }
    this.player = player;
    this.spinners = new Spinners();
    this.game = null;
    
    // Add pixelation settings
    this.context.imageSmoothingEnabled = false;       // Standard
    
    this.orchestrator = orchestrator;

    // Update zoom level to show 10 cells
    this.zoomLevel = 10;
    this.cameraX = 0;
    this.cameraY = 0;

    // Get existing chat bubbles container
    this.chatBubblesContainer = document.getElementById("chatBubblesContainer") as HTMLDivElement;
    if (!this.chatBubblesContainer) {
      throw new Error("Could not find chat bubbles container");
    }
    this.bubbles = {};

    this.tooltipCanvas = document.getElementById("tooltipCanvas") as HTMLCanvasElement;
    this.tooltipCtx = this.tooltipCanvas.getContext("2d") || (() => { throw new Error("Could not get tooltip canvas context") })();

    // Add camera smoothing properties
    this.targetCameraX = 0;
    this.targetCameraY = 0;
    this.cameraSmoothing = 0.15; // Adjust this value to change smoothing speed (0-1)

    this.selectedInventoryItem = null;

  

    this.renderingPaused = false;

    // Track discovered areas using ImageData
    this.fogData = null;
    this.discoveredAreas = null;
    this.game = null;

    this.errorModal = new ErrorModal();
  }

  startGame(game: Game) {
    this.game = game;
    this.setupTooltip();
    this.setupClickHandling();
    // Start the camera animation loop
    this.startCameraLoop();


    // Add fullscreen change handler
    document.addEventListener('fullscreenchange', () => this.handleFullscreenChange());
    document.addEventListener('webkitfullscreenchange', () => this.handleFullscreenChange());
    // Scroll to top when canvas is first loaded
    window.scrollTo(0, 0);
  }

  startCameraLoop() {
    const updateCamera = () => {
      // Smoothly interpolate camera position
      this.cameraX += (this.targetCameraX - this.cameraX) * this.cameraSmoothing;
      this.cameraY += (this.targetCameraY - this.cameraY) * this.cameraSmoothing;
      
      // Only redraw if camera moved significantly
      if (Math.abs(this.targetCameraX - this.cameraX) > 0.001 || 
          Math.abs(this.targetCameraY - this.cameraY) > 0.001) {
        this.draw();
      }
      
      requestAnimationFrame(updateCamera);
    };
    
    updateCamera();
  }

  draw() {
    if (this.renderingPaused || !this.world.level.terrain) return;
    try {
      const displayRect = this.canvas.getBoundingClientRect();
      this.canvas.width = displayRect.width;
      this.canvas.height = displayRect.height;

      // Reapply pixelation settings after canvas resize
      this.context.imageSmoothingEnabled = false;

      // Validate canvas dimensions
      if (this.canvas.width === 0 || this.canvas.height === 0) {
          console.warn('Invalid canvas dimensions, skipping draw');
          return;
      }

      // Clear the canvas
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      // Update target camera position to center on player
      this.targetCameraX = this.player.x - this.zoomLevel / 2 + 0.5;
      this.targetCameraY = this.player.y - this.zoomLevel / 2 + 0.5;

      // Calculate cell dimensions based on zoom level
      const cellWidth = this.canvas.width / this.zoomLevel;
      const cellHeight = this.canvas.height / this.zoomLevel;

      // Draw terrain
      this.#drawTerrain(cellWidth, cellHeight);

      // Draw all entities (including player)
      this.#drawEntities(cellWidth, cellHeight);

      // Apply lighting effects
      this.#applyLighting(cellWidth, cellHeight);

      // Update inventory display
      this.updateInventoryDisplay();

      // Update chat bubble positions after drawing
      this.updateChatBubblePositions();
    } catch (error) {
      console.error("Error drawing game:", error);
    }
  }

  #drawTerrain(cellWidth: number, cellHeight: number) {
    if (!this.world.level.terrain || !this.game?.terrainImages) return;
    // Calculate visible range
    const startX = Math.max(0, Math.floor(this.cameraX));
    const startY = Math.max(0, Math.floor(this.cameraY));
    const endX = Math.min(this.world.level.terrain[0].length, Math.ceil(this.cameraX + this.zoomLevel));
    const endY = Math.min(this.world.level.terrain.length, Math.ceil(this.cameraY + this.zoomLevel));

    for (let y = startY; y < endY; y++) {
        for (let x = startX; x < endX; x++) {
            const cellSymbol = this.world.level.terrain[y][x];
            const img = this.game.terrainImages[cellSymbol];
            
            // Calculate screen position
            const screenX = (x - this.cameraX) * cellWidth;
            const screenY = (y - this.cameraY) * cellHeight;

            this.context.drawImage(img, screenX, screenY, cellWidth, cellHeight);
        }
    }
  }

  #drawEntities(cellWidth: number, cellHeight: number) {
    if (!this.game?.entityImages) return;
    for (const entity of this.world.level.placedEntities) {
      // Only draw entities within the visible range
      if (this.isEntityVisible(entity)) {
        const img = this.game.entityImages[entity.uniqueId];
        if (img) {
          const screenX = (entity.x - this.cameraX) * cellWidth;
          const screenY = (entity.y - this.cameraY) * cellHeight;
          this.context.drawImage(img, screenX, screenY, cellWidth, cellHeight);
        }
      }
    }
  }

  isEntityVisible(entity: Entity, margin = 0.5) {
    return (
      entity.x >= this.cameraX - margin &&
      entity.x < this.cameraX + this.zoomLevel + margin &&
      entity.y >= this.cameraY - margin &&
      entity.y < this.cameraY + this.zoomLevel + margin
    );
  }

  #applyLighting(cellWidth: number, cellHeight: number) {
    // Create a separate canvas for the lighting overlay
    const lightingCanvas = document.createElement("canvas");
    lightingCanvas.width = this.canvas.width;
    lightingCanvas.height = this.canvas.height;
    const lightCtx = lightingCanvas.getContext("2d");
    if (!lightCtx) {
      throw new Error("Could not get lighting canvas context");
    }

    // Fill with unseen blackness
    lightCtx.fillStyle = "rgba(0, 0, 0, 1)";
    lightCtx.fillRect(0, 0, lightingCanvas.width, lightingCanvas.height);


    // Calculate player position and visibility range
    const px = Math.floor(this.player.x + 0.5);
    const py = Math.floor(this.player.y + 0.5);
    const maxRange = 6;

    // Get all light sources (including player and terrain)
    const lightSources = [
      // Add terrain light sources, using the greater of ambient and terrain light
      ...this.world.level.terrain
        .flatMap((row, y) =>
          row.map((symbol, x) => ({
            x: Math.floor(x + 0.5),
            y: Math.floor(y + 0.5),
            light: Math.max(
              this.world.level.terrainTypes[symbol].light || 0,
              this.world.level.terrainTypes[symbol].skyVisible ? 0.8 : 0,
              this.world.level.ambientLightGrid?.[y]?.[x] || 0
            ),
            color: this.world.level.terrainTypes[symbol].color,
            terrain: true,
          }))
        )
        .filter((source) => source.light > 0),
      // Add entity light sources with inventory consideration
      ...this.world.level.placedEntities
        .map(entity => {
          // Find the brightest light source (entity or inventory item)
          const inventoryItems = entity.inventory.map(item => ({
            light: item.light || 0,
            color: item.color
          }));
          const lightSources = [
            { light: entity.light || 0, color: entity.color },
            ...inventoryItems
          ];
          const brightestSource = lightSources.reduce((brightest, current) => 
            current.light > brightest.light ? current : brightest
          , { light: 0, color: entity.color });

          return {
            ...entity,
            x: Math.floor(entity.x + 0.5),
            y: Math.floor(entity.y + 0.5),
            light: brightestSource.light,
            color: brightestSource.color
          };
        })
        .filter(entity => entity.light > 0)
    ];

    
    // Apply entity lighting effects
    lightSources
      .filter(source => {
        if ('terrain' in source) {
          return !source.terrain;
        }
        return true;
      })
      .forEach(source => this.#applyLightSource(lightCtx, source, cellWidth, cellHeight));

    // Update visibility and apply lighting for cells in range
    for (let y = py - maxRange; y <= py + maxRange; y++) {
      for (let x = px - maxRange; x <= px + maxRange; x++) {
        if (x < 0 || y < 0 || x >= this.world.level.terrain[0].length || y >= this.world.level.terrain.length) {
          continue;
        }

        const cellSymbol = this.world.level.terrain[y][x];
        const terrainLight = Math.max(
          this.world.level.terrainTypes[cellSymbol].light || 0,
          this.world.level.terrainTypes[cellSymbol].skyVisible ? 0.8 : 0,
          this.world.level.ambientLightGrid?.[y]?.[x] || 0
        );

        // Calculate entity light contribution
        const entityLight = lightSources
          .filter(source => {
            if ('terrain' in source) {
              return !source.terrain;
            }
            return true;
          })
          .reduce((maxLight, source) => {
            const distToSource = Math.sqrt(Math.pow(x - source.x, 2) + Math.pow(y - source.y, 2));
            if (distToSource <= maxRange && this.hasLineOfSight(source.x, source.y, x, y)) {
              const lightAtDistance = source.light * (1 - distToSource / maxRange);
              return Math.max(maxLight, lightAtDistance);
            }
            return maxLight;
          }, 0);

        const distance = Math.sqrt(Math.pow(x - px, 2) + Math.pow(y - py, 2));
        const lit = terrainLight > 0.18 || entityLight > 0.18 || distance <= 1.5;

        // Calculate screen position
        const screenX = (x - this.cameraX) * cellWidth;
        const screenY = (y - this.cameraY) * cellHeight;

        // Check for fog of war
        if (this.world.level.discoveredAreas) {
          if (lit && this.hasLineOfSight(px, py, x, y)) {
            this.world.level.discoveredAreas[y * this.world.level.terrain[0].length + x] = 255;
            this.#applyLightSource(lightCtx, { x, y, light: Math.max(terrainLight, 0.1), terrain: true }, cellWidth, cellHeight);
          } else if (this.world.level.discoveredAreas[y * this.world.level.terrain[0].length + x]) {
            if (screenX + cellWidth >= 0 && screenX <= lightingCanvas.width && 
                screenY + cellHeight >= 0 && screenY <= lightingCanvas.height) {
                this.#applyLightSource(lightCtx, { x, y, light: 0.1, terrain: true }, cellWidth, cellHeight);
            }
          } else {
            this.#applyLightSource(lightCtx, { x, y, dark: 1 }, cellWidth, cellHeight);
          }
        }

      }
    }
    // Apply the lighting overlay to the main canvas
    this.context.drawImage(lightingCanvas, 0, 0);
  }

  #applyLightSource(lightCtx: CanvasRenderingContext2D, source: any, cellWidth: number, cellHeight: number) {
    if (source.terrain) {
      lightCtx.globalCompositeOperation = "destination-out";
      lightCtx.fillStyle = `rgba(0, 0, 0, ${source.light})`;
      const screenX = (source.x - this.cameraX) * cellWidth;
      const screenY = (source.y - this.cameraY) * cellHeight;
      lightCtx.fillRect(screenX, screenY, cellWidth, cellHeight);
    } else if (source.dark) {
      lightCtx.globalCompositeOperation = "source-over";
      lightCtx.fillStyle = `rgba(0, 0, 0, ${source.dark})`;
      const screenX = (source.x - this.cameraX) * cellWidth;
      const screenY = (source.y - this.cameraY) * cellHeight;
      lightCtx.fillRect(screenX, screenY, cellWidth, cellHeight);
    } else {
      const lightRadius = source.light * 6 * cellWidth;
      const innerRadius = lightRadius * 0.4;
      const lightColor = source.color || "#FFFFFF";
      const alpha = Math.min(0.9, source.light + 0.2);
      const screenX = (source.x - this.cameraX) * cellWidth + cellWidth / 2;
      const screenY = (source.y - this.cameraY) * cellHeight + cellHeight / 2;

      // Apply outer light
      const gradient = lightCtx.createRadialGradient(
        screenX, screenY, 0,
        screenX, screenY, lightRadius
      );
      gradient.addColorStop(0, `rgba(0, 0, 0, ${alpha})`);
      gradient.addColorStop(0.2, `rgba(0, 0, 0, ${alpha * 0.8})`);
      gradient.addColorStop(0.5, `rgba(0, 0, 0, ${alpha * 0.5})`);
      gradient.addColorStop(0.8, `rgba(0, 0, 0, ${alpha * 0.2})`);
      gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

      lightCtx.globalCompositeOperation = "destination-out";
      lightCtx.fillStyle = gradient;
      lightCtx.beginPath();
      lightCtx.arc(screenX, screenY, lightRadius, 0, Math.PI * 2);
      lightCtx.fill();

      // Apply inner glow
      lightCtx.globalCompositeOperation = "source-over";
      const glowGradient = lightCtx.createRadialGradient(
        screenX, screenY, 0,
        screenX, screenY, innerRadius
      );

      const toHex = (alpha: number) => Math.floor(alpha * 255).toString(16).padStart(2, "0");
      glowGradient.addColorStop(0, `${lightColor}${toHex(alpha * 0.3)}`);
      glowGradient.addColorStop(0.2, `${lightColor}${toHex(alpha * 0.15)}`);
      glowGradient.addColorStop(0.5, `${lightColor}${toHex(alpha * 0.075)}`);
      glowGradient.addColorStop(0.8, `${lightColor}${toHex(alpha * 0.03)}`);
      glowGradient.addColorStop(1, `${lightColor}00`);

      lightCtx.fillStyle = glowGradient;
      lightCtx.beginPath();
      lightCtx.arc(screenX, screenY, innerRadius, 0, Math.PI * 2);
      lightCtx.fill();
    }
  }

  setupTooltip() {
    // Add mouse handling
    this.canvas.addEventListener("mousemove", (e) => this.handleMouseMove(e));
    this.canvas.addEventListener("mouseout", () => this.hideTooltip());

    // Add touch handling for spyglass
    const spyglassButton = document.getElementById('spyglassButton');
    if (spyglassButton) {
      let isSpyglassActive = false;
      let touchId: number | null = null;
      let draggedSpyglass: HTMLDivElement | null = null;

      spyglassButton.addEventListener('touchstart', (e) => {
        e.preventDefault();
        isSpyglassActive = true;
        touchId = e.touches[0].identifier;
        spyglassButton.classList.add('active');

        // Create dragged spyglass element
        draggedSpyglass = document.createElement('div');
        draggedSpyglass.className = 'dragged-spyglass';
        draggedSpyglass.textContent = '🔍';
        draggedSpyglass.style.position = 'absolute';
        draggedSpyglass.style.zIndex = '1001';
        document.body.appendChild(draggedSpyglass);

        // Position the dragged element at touch position, accounting for scroll
        const touch = e.touches[0];
        const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollY = window.pageYOffset || document.documentElement.scrollTop;
        draggedSpyglass.style.left = `${touch.clientX + scrollX}px`;
        draggedSpyglass.style.top = `${touch.clientY + scrollY}px`;
      });

      document.addEventListener('touchmove', (e) => {
        if (!isSpyglassActive) return;
        e.preventDefault();
        
        const touch = Array.from(e.touches).find(t => t.identifier === touchId);
        if (touch && draggedSpyglass) {
          // Update dragged spyglass position, accounting for scroll
          const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
          const scrollY = window.pageYOffset || document.documentElement.scrollTop;
          draggedSpyglass.style.left = `${touch.clientX + scrollX - 25}px`;
          draggedSpyglass.style.top = `${touch.clientY + scrollY - 25}px`;

          // Check if touch is over an inventory slot
          const inventorySlots = document.getElementsByClassName('inventorySlot');
          const hoveredSlot = Array.from(inventorySlots).find(slot => {
            const rect = slot.getBoundingClientRect();
            return touch.clientX >= rect.left && 
                   touch.clientX <= rect.right && 
                   touch.clientY >= rect.top && 
                   touch.clientY <= rect.bottom;
          });

          if (hoveredSlot) {
            const tooltipContent = hoveredSlot.getAttribute('data-tooltip');
            if (tooltipContent) {
              const rect = hoveredSlot.getBoundingClientRect();
              this.showInventoryTooltip(tooltipContent, rect.right + 5, rect.top + scrollY);
              return;
            }
          }

          // Check if touch is over the game canvas
          const rect = this.canvas.getBoundingClientRect();
          const x = touch.clientX - rect.left;
          const y = touch.clientY - rect.top;

          if (x < 0 || x > rect.width || y < 0 || y > rect.height) {
            this.hideTooltip();
            return;
          }

          this.handleMouseMove({
            clientX: touch.clientX,
            clientY: touch.clientY
          });
        }
      }, { passive: false });

      const endSpyglass = (e: TouchEvent) => {
        if (e.type === 'touchend' || e.type === 'touchcancel') {
          const changedTouch = Array.from(e.changedTouches).find((t: Touch) => t.identifier === touchId);
          if (!changedTouch) return;
        }
        
        isSpyglassActive = false;
        touchId = null;
        spyglassButton.classList.remove('active');
        this.hideTooltip();

        // Remove dragged spyglass
        if (draggedSpyglass) {
          draggedSpyglass.remove();
          draggedSpyglass = null;
        }
      };

      document.addEventListener('touchend', endSpyglass);
      document.addEventListener('touchcancel', endSpyglass);
    }
  }

  handleMouseMove(event: { clientX: number, clientY: number }) {
    if (!this.game) throw new Error('Game not found');
    if (!this.game.terrainImages) throw new Error('Terrain images not found');
    if (!this.game.entityImages) throw new Error('Entity images not found');
    const rect = this.canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Check if mouse/touch is outside the canvas bounds
    if (x < 0 || x > rect.width || y < 0 || y > rect.height) {
        this.hideTooltip();
        return;
    }

    // Use canvas dimensions for cell size calculation
    const cellWidth = rect.width / this.zoomLevel;
    const cellHeight = rect.height / this.zoomLevel;

    // Calculate exact mouse position in world coordinates
    const worldX = this.cameraX + (x / cellWidth);
    const worldY = this.cameraY + (y / cellHeight);

    // Get entities near the mouse position (using a small radius)
    const radius = 0.5; // Adjust this value to control how close the mouse needs to be
    const nearbyEntities = this.world.level.placedEntities.filter(entity => {
        const dx = entity.x + 0.5 - worldX;
        const dy = entity.y + 0.5 - worldY;
        return Math.sqrt(dx * dx + dy * dy) <= radius;
    });

    // Get the grid coordinates for terrain
    const gridX = Math.floor(worldX);
    const gridY = Math.floor(worldY);

    // Update cursor style and prepare tooltip content
    if (gridX >= 0 && gridX < this.world.level.width && gridY >= 0 && gridY < this.world.level.height) {
        const isDiscovered = this.world.level.discoveredAreas?.[gridY * this.world.level.terrain[0].length + gridX];
        
        if (!isDiscovered) {
            // Show "Unknown" for undiscovered tiles
            const lines = [`(${gridX}, ${gridY})`, "Unknown"];
            this.drawTooltip(lines, [], event.clientX, event.clientY);
            return;
        }
        
        if (!this.selectedInventoryItem) {
            this.canvas.style.cursor = nearbyEntities.length > 0 ? 'pointer' : 'default';
        }
        
        // Prepare content for drawing
        const lines = [];
        const images = [];
        lines.push(`(${gridX}, ${gridY})`);

        // Add terrain info and image
        if (this.world.level.terrain && this.world.level.terrainTypes) {
            const cellSymbol = this.world.level.terrain[gridY][gridX];
            const terrainType = this.world.level.terrainTypes[cellSymbol];
            const lineIndex = lines.length;
            lines.push(terrainType.name);
            if (this.game?.terrainImages[cellSymbol]) {
                images.push({
                    img: this.game.terrainImages[cellSymbol],
                    lineIndex,
                });
            }
        }

        // Add entity info and image
        if (nearbyEntities.length > 0) {
            nearbyEntities.forEach((entity) => {
                const lineIndex = lines.length;
                lines.push(`- ${entity.description || entity.uniqueId}`);
                if (!this.game?.entityImages) throw new Error('Entity images not found');
                if (this.game.entityImages[entity.uniqueId]) {
                    images.push({
                        img: this.game.entityImages[entity.uniqueId],
                        lineIndex,
                    });
                }

                // Only show inventory for player entity
                if (entity === this.player || process.env.NODE_ENV === "development") {
                    const inventory = entity.getInventoryContents();
                    if (inventory.length > 0) {
                        lines.push("Inventory:");
                        inventory.forEach((item) => {
                            if (!this.game?.entityImages) throw new Error('Entity images not found');
                            const lineIndex = lines.length;
                            lines.push(`    ${item.displayName}`);
                            if (this.game.entityImages[item.uniqueId]) {
                                images.push({
                                    img: this.game.entityImages[item.uniqueId],
                                    lineIndex,
                                });
                            }
                        });
                    }
                }
            });
        }

        // For mouse events, use normal positioning
        this.drawTooltip(lines, images, event.clientX, event.clientY);
    } else {
        this.hideTooltip();
    }
  }

  calculateTooltipPosition(width: number, height: number, anchorX: number, anchorY: number, options: { offsetX?: number, offsetY?: number } = {}) {
    const {
      offsetX = -10,  // Default offset from cursor
      offsetY = -10
    } = options;

    // Get viewport dimensions and scroll position
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Limit width to viewport width minus margins
    const maxWidth = Math.min(width, viewportWidth - 20);

    // Calculate initial position based on offset direction
    let left = offsetX >= 0 ? anchorX + offsetX : anchorX - maxWidth + offsetX;
    let top = offsetY >= 0 ? anchorY + offsetY : anchorY - height + offsetY;

    // Check viewport bounds and adjust if necessary
    if (left + maxWidth > viewportWidth) {
      left = anchorX - maxWidth - Math.abs(offsetX);
    }
    if (left < 0) {
      left = anchorX + Math.abs(offsetX);
    }

    // Check vertical bounds
    if (top + height > scrollTop + viewportHeight) {
      top = anchorY - height - Math.abs(offsetY);
    }
    if (top < scrollTop) {
      top = anchorY + Math.abs(offsetY);
    }

    // Final bounds check - ensure tooltip is always visible
    left = Math.max(10, Math.min(left, viewportWidth - maxWidth - 10));
    top = Math.max(scrollTop + 10, Math.min(top, scrollTop + viewportHeight - height - 10));

    return { left, top };
  }

  drawTooltip(lines: string[], images: { img: HTMLImageElement, lineIndex: number }[], clientX: number, clientY: number) {
    // Update font size
    this.tooltipCtx.font = "16px Arial";
    const padding = 5;
    const lineHeight = 18;
    const imageSize = 16;
    const imageMargin = 3;

    // Get canvas width as max width for wrapping
    const maxTooltipWidth = this.canvas.width * 0.8;

    // Word wrap lines that are too long
    const wrappedLines: string[] = [];
    lines.forEach(line => {
        if (this.tooltipCtx.measureText(line).width > maxTooltipWidth - (padding * 2)) {
            // Split into words
            const words = line.split(' ');
            let currentLine = '';

            words.forEach(word => {
                const testLine = currentLine + (currentLine ? ' ' : '') + word;
                const testWidth = this.tooltipCtx.measureText(testLine).width;

                if (testWidth > maxTooltipWidth - (padding * 2)) {
                    wrappedLines.push(currentLine);
                    currentLine = word;
                } else {
                    currentLine = testLine;
                }
            });

            if (currentLine) {
                wrappedLines.push(currentLine);
            }
        } else {
            wrappedLines.push(line);
        }
    });

    // Calculate final dimensions
    const textWidth = Math.min(
        maxTooltipWidth - (padding * 2),
        Math.max(...wrappedLines.map(line => this.tooltipCtx.measureText(line).width))
    );
    const totalWidth = textWidth + padding * 2 + (images.length > 0 ? imageSize + imageMargin : 0);
    const height = wrappedLines.length * lineHeight + padding * 2;

    // Size the canvas
    this.tooltipCanvas.width = totalWidth;
    this.tooltipCanvas.height = height;
    
    // Set canvas CSS size
    this.tooltipCanvas.style.width = `${totalWidth}px`;
    this.tooltipCanvas.style.height = `${height}px`;

    // Draw tooltip background
    this.tooltipCtx.fillStyle = "rgba(0, 0, 0, 0.8)";
    this.tooltipCtx.fillRect(0, 0, totalWidth, height);

    // Draw border
    this.tooltipCtx.strokeStyle = "#666";
    this.tooltipCtx.strokeRect(0, 0, totalWidth, height);

    // Draw text
    this.tooltipCtx.fillStyle = "white";
    this.tooltipCtx.font = "16px Arial";
    this.tooltipCtx.textBaseline = "top";
    wrappedLines.forEach((line, index) => {
        this.tooltipCtx.fillText(line, padding, padding + index * lineHeight);
    });

    // Draw images
    images.forEach(({ img, lineIndex }) => {
        // Find the actual Y position after wrapping
        let actualLineIndex = 0;
        let currentOriginalLine = 0;
        
        for (let i = 0; i < wrappedLines.length; i++) {
            if (currentOriginalLine === lineIndex) {
                actualLineIndex = i;
                break;
            }
            if (wrappedLines[i] === lines[currentOriginalLine]) {
                currentOriginalLine++;
            }
        }

        const yOffset = padding + actualLineIndex * lineHeight;
        const xOffset = textWidth + padding + imageMargin;
        this.tooltipCtx.drawImage(img, xOffset, yOffset, imageSize, imageSize);
    });

    const options = {};

    // Calculate position
    const { left, top } = this.calculateTooltipPosition(
        totalWidth,
        height,
        clientX,
        clientY,
        options
    );

    // Position tooltip
    this.tooltipCanvas.style.display = "block";
    this.tooltipCanvas.style.left = `${left}px`;
    this.tooltipCanvas.style.top = `${top}px`;
  }

  hideTooltip() {
    this.tooltipCanvas.style.display = "none";
  }

  updateInventoryDisplay() {
    if (!this.player || !this.game?.inventoryPanel) throw new Error('Player or inventory panel not found');

    // Clear existing content but keep the slots
    const slots = this.game.inventoryPanel.getElementsByClassName("inventorySlot");
    Array.from(slots).forEach(slot => {
        // Just clear the content instead of replacing the entire slot
        while (slot.firstChild) {
            slot.removeChild(slot.firstChild);
        }
        // Remove old tooltip data
        slot.removeAttribute("data-tooltip");
    });

    // Fill slots with player's inventory items
    if (this.player.inventory) {
        this.player.inventory.forEach((item, index) => {
            if (!this.game?.entityImages) throw new Error('Entity images not found');
            if (index < slots.length && this.game.entityImages[item.uniqueId]) {
                const slot = slots[index] as HTMLElement;
                
                // Create and append image
                const img = document.createElement("img");
                img.src = this.game.entityImages[item.uniqueId].src;
                slot.appendChild(img);

                // Create tooltip content
                const tooltipContent = [
                    item.displayName || item.id,
                    item.description || "",
                ].filter(Boolean).join("\n");

                slot.setAttribute("data-tooltip", tooltipContent);

                // Store item reference directly on the slot
                slot.dataset.itemIndex = index.toString();
            }
        });
    }
  }

  showInventoryTooltip(content: string, x: number, y: number) {
    const lines = content.split("\n");

    // Update font size
    this.tooltipCtx.font = "16px Arial";
    const padding = 5;
    const lineHeight = 18;

    // Calculate width and height
    const textWidth = Math.max(
      ...lines.map((line) => this.tooltipCtx.measureText(line).width)
    );
    const maxWidth = textWidth + padding * 2;
    const height = lines.length * lineHeight + padding * 2;

    // Size the canvas
    this.tooltipCanvas.width = maxWidth;
    this.tooltipCanvas.height = height;
    
    // Set canvas CSS size
    this.tooltipCanvas.style.width = `${maxWidth}px`;
    this.tooltipCanvas.style.height = `${height}px`;

    // Draw tooltip background
    this.tooltipCtx.fillStyle = "rgba(0, 0, 0, 0.8)";
    this.tooltipCtx.fillRect(0, 0, maxWidth, height);

    // Draw border
    this.tooltipCtx.strokeStyle = "#666";
    this.tooltipCtx.strokeRect(0, 0, maxWidth, height);

    // Draw text
    this.tooltipCtx.fillStyle = "white";
    this.tooltipCtx.font = "16px Arial";
    this.tooltipCtx.textBaseline = "top";
    lines.forEach((line, index) => {
      this.tooltipCtx.fillText(line, padding, padding + index * lineHeight);
    });

    const { left, top } = this.calculateTooltipPosition(
      maxWidth,
      height,
      x,
      y
    );

    // Position tooltip
    this.tooltipCanvas.style.display = "block";
    this.tooltipCanvas.style.left = `${left}px`;
    this.tooltipCanvas.style.top = `${top}px`;
  }

  setupClickHandling() {
    if (!this.canvas) throw new Error('Canvas not found');
    if (!this.game?.inventoryPanel) throw new Error('Inventory panel not found');

    // Add existing canvas click handling
    this.canvas.addEventListener('click', (e) => this.handleClick(e));
    this.canvas.addEventListener('touchstart', (e) => {
        e.preventDefault();
        const touch = e.touches[0];
        this.handleClick({
            clientX: touch.clientX,
            clientY: touch.clientY
        });
    });

    // Add inventory panel event delegation
    if (this.game.inventoryPanel) {
        // Remove any existing listeners first
        this.game.inventoryPanel.removeEventListener('click', this.inventoryClickHandler);
        this.game.inventoryPanel.removeEventListener('mouseover', this.inventoryMouseOverHandler);
        this.game.inventoryPanel.removeEventListener('mouseout', this.inventoryMouseOutHandler);



        // Add event listeners using delegation
        this.game.inventoryPanel.addEventListener('click', this.inventoryClickHandler);
        this.game.inventoryPanel.addEventListener('mouseover', this.inventoryMouseOverHandler);
        this.game.inventoryPanel.addEventListener('mouseout', this.inventoryMouseOutHandler);
    }
  }
          // Create bound event handlers
          inventoryClickHandler = (e: MouseEvent) => {
            const slot = (e.target as HTMLElement)?.closest('.inventorySlot') as HTMLElement | null;  
            if (!slot) return;

            e.preventDefault();
            e.stopPropagation();
            if (!slot.dataset.itemIndex) throw new Error('Item index not found');
            const itemIndex = parseInt(slot.dataset.itemIndex);
            if (isNaN(itemIndex)) return;

            // Clear all selected states first
            if (!this.game?.inventoryPanel) throw new Error('Inventory panel not found');
            Array.from(this.game.inventoryPanel.getElementsByClassName('inventorySlot'))
                .forEach(s => s.classList.remove('selected'));

            // If clicking the currently selected item, deselect it
            if (this.selectedInventoryItem && this.selectedInventoryItem.index === itemIndex) {
                this.selectedInventoryItem = null;
                this.canvas.style.cursor = 'default';
                this.hideSelectedItemCursor();
            } else {
                // Select the new item
                const item = this.player.inventory[itemIndex];
                this.selectedInventoryItem = { item, index: itemIndex };
                slot.classList.add('selected');
                this.canvas.style.cursor = 'crosshair';
                this.showSelectedItemCursor(item);
            }
        };

        inventoryMouseOverHandler = (e: MouseEvent) => {
            const slot = (e.target as HTMLElement)?.closest('.inventorySlot') as HTMLElement | null;
            if (!slot) return;
            if (!slot.dataset.tooltip) return;

            const rect = slot.getBoundingClientRect();
            this.showInventoryTooltip(slot.dataset.tooltip, rect.right + 5, rect.top);
        };

        inventoryMouseOutHandler = () => {
            this.hideTooltip();
        };

  handleClick(event: { clientX: number, clientY: number }) {
    if (!this.game) throw new Error('Game not found');
    const rect = this.canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Use canvas dimensions for cell size calculation
    const cellWidth = rect.width / this.zoomLevel;
    const cellHeight = rect.height / this.zoomLevel;

    // Calculate exact mouse position in world coordinates
    const worldX = this.cameraX + (x / cellWidth);
    const worldY = this.cameraY + (y / cellHeight);

    // Get entities near the click position, accounting for the 0.5 offset
    const radius = 0.5; // Adjust this value to control click precision
    const clickedEntities = this.world.level.placedEntities.filter(entity => {
        const dx = (entity.x + 0.5) - worldX;
        const dy = (entity.y + 0.5) - worldY;
        return Math.sqrt(dx * dx + dy * dy) <= radius;
    });

    // Get the grid coordinates for terrain/item placement
    const gridX = Math.floor(worldX);
    const gridY = Math.floor(worldY);

    if (gridX >= 0 && gridX < this.world.level.width && 
        gridY >= 0 && gridY < this.world.level.height) {
        
        // If we have a selected item and the clicked square is adjacent and empty
        if (this.selectedInventoryItem && 
            clickedEntities.length === 0 && 
            Math.abs(this.player.x - gridX) <= 1 && 
            Math.abs(this.player.y - gridY) <= 1) {
            
            this.game.dropItemAtLocation(this.selectedInventoryItem.item, gridX, gridY);
            this.clearInventorySelection();
            return;
        }

        if (clickedEntities.length > 0) {
            const selectedItem = this.selectedInventoryItem?.item || null;
            this.game.handleEntityClick(clickedEntities[0], selectedItem);
            this.clearInventorySelection();
        }
    }
  }

  updateChatBubblePositions() {
    const bubbles = this.chatBubblesContainer.getElementsByClassName("chatBubble");
    (Array.from(bubbles) as HTMLDivElement[]).forEach((bubble: HTMLDivElement) => {
        const entity = this.world.level.placedEntities.find(e => this.bubbles[e.uniqueId]?.bubble === bubble);
        if (!entity) {
            bubble.remove();
            return;
        }

        // Check if entity is visible
        const isVisible = this.isEntityVisible(entity, -0.75); // small margin due to bubble border
        if (!isVisible) {
            bubble.style.opacity = "0";
            setTimeout(() => {
                if (bubble.style.opacity === "0") {
                    bubble.style.display = "none";
                }
            }, 300);
            return;
        }

        // Calculate new position
        const { left, top, isAbove, pointerPosition } = this.calculateBubblePosition(entity, bubble);

        // Update position
        bubble.style.left = `${left}px`;
        bubble.style.top = `${top}px`;

        // Update bubble classes and pointer position
        bubble.classList.remove("above", "below");
        bubble.classList.add(isAbove ? "above" : "below");
        
        // Update pointer position
        bubble.style.setProperty('--pointer-position', `${pointerPosition}px`);
    });
  }

  calculateBubblePosition(entity: Entity, bubble: HTMLDivElement) {
    const cellWidth = this.canvas.width / this.zoomLevel;
    const cellHeight = this.canvas.height / this.zoomLevel;
    const canvasRect = this.canvas.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Set fixed dimensions for bubbles on mobile
    if (window.innerWidth <= 768) {
        bubble.style.maxWidth = '200px';
    }

    const bubbleHeight = bubble.offsetHeight;
    const bubbleWidth = bubble.offsetWidth;

    // Calculate entity center position in screen space
    const screenX = (entity.x - this.cameraX) * cellWidth + (cellWidth / 2);
    const screenY = (entity.y - this.cameraY) * cellHeight + (cellHeight / 2);

    // Determine if bubble should be above/below based on player position
    let isAbove = entity === this.player || entity.y < this.player.y;

    // Calculate initial position based on player position
    let left = canvasRect.left + screenX - (bubbleWidth / 2);
    let top = isAbove 
        ? canvasRect.top + screenY - bubbleHeight - (cellHeight * 0.5) // Above entity
        : canvasRect.top + screenY + (cellHeight * 0.5);              // Below entity

    // Store original position for pointer positioning
    const originalScreenX = screenX;

    // Adjust horizontal position if needed
    if (left + bubbleWidth > viewportWidth) {
        left = viewportWidth - bubbleWidth - 10;
    }
    if (left < 0) {
        left = 10;
    }

    // If bubble would go off screen vertically, flip to other side
    if (isAbove && top < 0) {
        top = canvasRect.top + screenY + (cellHeight * 0.5);
        isAbove = false;
    } else if (!isAbove && top + bubbleHeight > viewportHeight) {
        top = canvasRect.top + screenY - bubbleHeight - (cellHeight * 0.5);
        isAbove = true;
    }

    // Calculate pointer position relative to bubble
    const pointerPosition = Math.max(10, Math.min(
        bubbleWidth - 10,
        originalScreenX - (left - canvasRect.left)
    ));

    return { left, top, isAbove, pointerPosition };
  }

  showMessage(entity: Entity, narration = false) {
    if (!entity.message) return;

    // Create or get existing bubble
    let bubble = this.bubbles[entity.uniqueId]?.bubble;
    if (!bubble?.classList) {
      bubble = document.createElement("div");
      bubble.className = "chatBubble";
      bubble.style.opacity = "0";
      bubble.style.display = "block";
      bubble.style.visibility = "hidden";
      this.chatBubblesContainer.appendChild(bubble);
      this.bubbles[entity.uniqueId] = { bubble, timeout: null };
    }

    // Reset bubble state
    bubble.style.display = "block";
    bubble.style.visibility = "visible";

    if (narration) {
      bubble.classList.add("narration");
    } else {
      bubble.classList.remove("narration");
    }

    // Clear any existing timeout
    if (this.bubbles[entity.uniqueId]?.timeout) {
      const timeout: NodeJS.Timeout | null = this.bubbles[entity.uniqueId]?.timeout||null;
      if (timeout) clearTimeout(timeout);
    }

    bubble.textContent = entity.message;
    bubble.offsetHeight; // trigger reflow

    this.updateChatBubblePositions();
    bubble.style.opacity = "1";

    // Use shared helper for timeout calculation
    const timeout = Entity.calculateMessageDisplayTime(entity.message);

    this.bubbles[entity.uniqueId] = { bubble, timeout: setTimeout(() => {
      if (bubble.style.display === "block") {
        bubble.style.opacity = "0";
        setTimeout(() => {
          bubble.style.display = "none";
          bubble.style.visibility = "visible";
        }, 300);
      }
      this.bubbles[entity.uniqueId] = { bubble, timeout: null };
    }, timeout) };
  }

  hideMessage(entity: Entity) {
    const bubble = this.bubbles[entity.uniqueId]?.bubble;
    if (bubble) {
      bubble.style.opacity = "0";
      setTimeout(() => {
        bubble.style.display = "none";
        bubble.style.visibility = "visible"; // Reset visibility for next use
      }, 300);
    }
  }

  // Add method to instantly update camera (useful for teleports/level changes)
  snapCameraToPosition(x: number, y: number) {
    this.targetCameraX = x - this.zoomLevel / 2;
    this.targetCameraY = y - this.zoomLevel / 2;
    this.cameraX = this.targetCameraX;
    this.cameraY = this.targetCameraY;
    this.draw();
  }

  showActionMenu(actions: Action[], entity: Entity) {
    if (!this.game) throw new Error('Game not found');
    // Remove any existing menu
    const existingMenu = document.getElementById('actionMenu');
    if (existingMenu) existingMenu.remove();

    // Create menu
    const menu = document.createElement('div');
    menu.id = 'actionMenu';
    menu.style.position = 'absolute';
    
    // Position menu near entity using camera and zoom, accounting for 0.5 offset
    const cellWidth = this.canvas.width / this.zoomLevel;
    const cellHeight = this.canvas.height / this.zoomLevel;
    const canvasRect = this.canvas.getBoundingClientRect();
    
    // Calculate screen position using exact entity coordinates plus 0.5 offset
    const screenX = (entity.x + 0.5 - this.cameraX) * cellWidth;
    const screenY = (entity.y + 0.5 - this.cameraY) * cellHeight;
    
    menu.style.left = `${canvasRect.left + screenX + cellWidth}px`;
    menu.style.top = `${canvasRect.top + screenY}px`;

    if (!actions || actions.length === 0) {
      // Add message for no actions
      const message = document.createElement('div');
      message.textContent = 'Nothing to be done...';
      message.className = 'action-menu-message';
      menu.appendChild(message);
    } else {
      // Add actions to menu
      actions.forEach(action => {
        const button = document.createElement('button');
        
        // Check all result types that affect inventory
        const willTakeItem = action.results.some(result => result.type === 'take_item');
        const willGiveItem = action.results.some(result => 
          result.type === 'give_new_item' ||
          result.type === 'give_item_from_inventory' ||
          result.type === 'give_this_to_player'
        );
        
        // Create button text with item requirements if needed
        let buttonText = action.name;
        if (willTakeItem) {
          const takeResults = action.results.filter(r => r.type === 'take_item');
          const requiredItems = takeResults.map(result => {
            const itemToTake = this.player.inventory.find(item => item.uniqueId === result.itemId);
            return itemToTake?.displayName || 'Unknown Item';
          });
          buttonText += ` (Will lose: ${requiredItems.join(' & ')})`;
        }
        
        // Check inventory constraints
        const inventoryIsFull = this.player.inventory.length >= this.player.maxInventorySize;
        let isDisabled = false;
        let disableReason = '';

        // Check if any required items are missing
        if (willTakeItem) {
          const takeResults = action.results.filter(r => r.type === 'take_item');
          const missingItems = takeResults.filter(result => 
            !this.player.inventory.some(item => item.uniqueId === result.itemId)
          );
          
          if (missingItems.length > 0) {
            isDisabled = true;
            disableReason = '(Missing required items)';
          }
        }

        // Check if inventory is full for any action that gives items
        if (willGiveItem && inventoryIsFull) {
          isDisabled = true;
          disableReason = '(Inventory is full)';
        }

        button.textContent = isDisabled ? `${buttonText} ${disableReason}` : buttonText;
        button.disabled = isDisabled;
        if (isDisabled) {
          button.classList.add('disabled');
        }
        button.title = action.actionDescription;
        
        button.onclick = async () => {
          menu.remove();
          if (!this.game) throw new Error('Game not found');
          try {
            await this.game.executeAction(action, entity);
          } catch (error) {
            console.error('Error executing action:', error);
          }
          this.game.resumeGameLoop();
        };
        menu.appendChild(button);
      });
    }

    // Add close button
    const closeButton = document.createElement('button');
    closeButton.textContent = 'Cancel';
    closeButton.onclick = () => {
      menu.remove();
      if (!this.game) throw new Error('Game not found');
      this.game.resumeGameLoop();
    };
    menu.appendChild(closeButton);

    document.body.appendChild(menu);

    // Adjust position if menu would go off screen
    const menuRect = menu.getBoundingClientRect();
    if (menuRect.right > window.innerWidth) {
      menu.style.left = `${canvasRect.left + screenX - menuRect.width}px`;
    }
    if (menuRect.bottom > window.innerHeight) {
      menu.style.top = `${canvasRect.top + screenY - menuRect.height}px`;
    }
  }


  handleFullscreenChange() {
    // Force a resize event to update canvas dimensions
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      this.draw();
    }, 100);
  }

  clearInventorySelection() {
    this.selectedInventoryItem = null;
    this.canvas.style.cursor = 'default';
    this.hideSelectedItemCursor();
    if (!this.game) throw new Error('Game not found');
    if (!this.game.inventoryPanel) throw new Error('Inventory panel not found');
    const slots = this.game.inventoryPanel.getElementsByClassName("inventorySlot");
    Array.from(slots).forEach(slot => slot.classList.remove('selected'));
  }

  showSelectedItemCursor(item: Entity | null) {
    // Remove any existing cursor item
    this.hideSelectedItemCursor();

    // Create cursor item element
    const cursorItem = document.createElement('div');
    cursorItem.id = 'cursorItem';
    cursorItem.style.cssText = `
        position: fixed;
        pointer-events: none;
        z-index: 10000;
        transform: translate(10px, 10px);
    `;

    // Create and add image
    const img = document.createElement('img');
    if (!this.game?.entityImages) throw new Error('Entity images not found');
    if (!item) throw new Error('Item not found');
    img.src = this.game.entityImages[item.uniqueId]?.src;
    img.style.width = '32px';
    img.style.height = '32px';
    cursorItem.appendChild(img);

    // Add to document
    document.body.appendChild(cursorItem);


    document.addEventListener('mousemove', this.updateCursorPosition);
    cursorItem.dataset.moveListener = 'true';
  }

  // Update cursor item position on mouse move
  updateCursorPosition = (e: MouseEvent) => {
    const cursorItem = document.getElementById('cursorItem');
    if (!cursorItem) throw new Error('Cursor item not found');
    cursorItem.style.left = `${e.clientX}px`;
    cursorItem.style.top = `${e.clientY}px`;
  };

  hideSelectedItemCursor() {
    const cursorItem = document.getElementById('cursorItem');
    if (cursorItem) {
        if (cursorItem.dataset.moveListener) {
            document.removeEventListener('mousemove', this.updateCursorPosition);
        }
        cursorItem.remove();
    }
  }

  pauseRendering() {
    this.renderingPaused = true;
  }

  resumeRendering() {
    this.renderingPaused = false;
  }


  hasLineOfSight(x0: number, y0: number, x1: number, y1: number) {
    // Bresenham's line algorithm (same as before)
    const dx = Math.abs(x1 - x0);
    const dy = Math.abs(y1 - y0);
    const sx = x0 < x1 ? 1 : -1;
    const sy = y0 < y1 ? 1 : -1;
    let err = dx - dy;
    
    let x = x0;
    let y = y0;

    while (true) {
      if (x === x1 && y === y1) return true;

      if (x !== x0 || y !== y0) {
        const cellSymbol = this.world.level.terrain[Math.floor(y)][Math.floor(x)];
        const terrainType = this.world.level.terrainTypes[cellSymbol];
        if (!terrainType.passable) return false;
      }

      const e2 = 2 * err;
      if (e2 > -dy) {
        err -= dy;
        x += sx;
      }
      if (e2 < dx) {
        err += dx;
        y += sy;
      }
    }
  }

  showError(title: string, message: string) {
    this.errorModal.show(title, message, () => {
      this.resumeRendering();
    });
    this.pauseRendering();
  }

  async switchWorlds(world: World) {
    // Update world reference
    this.world = world;
    
    // Get new player reference from the world
    const player = world.getPlayer();
    if (!player) {
      throw new Error("Player not found in new world");
    }
    this.player = player;

    // Reset camera and fog data
    this.fogData = null;
    this.discoveredAreas = null;

    // Clear any existing chat bubbles
    Object.values(this.bubbles).forEach(({ bubble, timeout }) => {
      if (timeout) clearTimeout(timeout);
      if (bubble) bubble.remove();
    });
    this.bubbles = {};

    // Clear any inventory selection
    this.clearInventorySelection();

    // Snap camera to new player position
    this.snapCameraToPosition(player.x, player.y);

    // Force a redraw
    this.draw();
  }
} 