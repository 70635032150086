import { auth } from '../config/firebase';
import { GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';

type AuthListener = (user: User | null) => void;

class AuthService {
  private user: User | null = null;
  private listeners: Set<AuthListener> = new Set();
  private loading: boolean = true;

  constructor() {
    auth.onAuthStateChanged((user) => {
      this.user = user;
      this.loading = false;
      this.notifyListeners();
    });
  }

  private notifyListeners() {
    this.listeners.forEach(listener => listener(this.user));
  }

  subscribe(listener: AuthListener) {
    this.listeners.add(listener);
    // Immediately notify the new listener of the current state
    listener(this.user);
    
    // Return unsubscribe function
    return () => this.listeners.delete(listener);
  }

  async signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  }

  signOut() {
    return auth.signOut();
  }

  getUser() {
    return this.user;
  }

  isLoading() {
    return this.loading;
  }
}

// Create and export a singleton instance
export const authService = new AuthService(); 