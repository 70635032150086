import { authService } from '../contexts/AuthContext';
import { Game } from '../Game';
import '../styles/feedback.css';

export class FeedbackModal {
  private game: Game;
  private modal: HTMLDivElement;

  constructor(game: Game) {
    this.game = game;
    
    this.modal = document.createElement('div');
    this.modal.className = 'feedback-modal';
    this.modal.innerHTML = `
      <div class="feedback-content">
        <h3>Send Feedback</h3>
        <p class="feedback-note">Your game state will be included to help us understand and improve the game.</p>
        <textarea 
          id="feedbackText" 
          placeholder="Tell us what you think about the game..."
        ></textarea>
        <div class="feedback-buttons">
          <button id="cancelFeedback">Cancel</button>
          <button id="submitFeedback">Send</button>
        </div>
      </div>
    `;

    this.setupListeners();
  }

  setupListeners() {
    const submit = this.modal.querySelector('#submitFeedback');
    const cancel = this.modal.querySelector('#cancelFeedback');
    const textarea = this.modal.querySelector('#feedbackText') as HTMLTextAreaElement;
    if (!textarea) throw new Error("Feedback textarea not found");

    submit?.addEventListener('click', async () => {
      const feedback = textarea.value.trim();
      if (!feedback) return;

      try {
        const shortId = await this.game.orchestrator.saveWorld(this.game.world);
        const idToken = await authService.getUser()?.getIdToken();
        
        const response = await fetch('/api/feedback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken || ''}`
          },
          body: JSON.stringify({
            feedback,
            metadata: {
              timestamp: new Date(),
              gameState: `${window.location.origin}?load=${shortId}`
            }
          })
        });

        if (response.ok) {
          textarea.value = '';
          alert('Thank you for your feedback!');
          this.hide();
        }
      } catch (error) {
        console.error('Error sending feedback:', error);
        alert('Failed to send feedback. Please try again.');
      }
    });

    cancel?.addEventListener('click', () => this.hide());
  }

  show() {
    document.body.appendChild(this.modal);
    if (this.game) this.game.pauseGameLoop();
  }

  hide() {
    this.modal.remove();
    if (this.game) this.game.resumeGameLoop();
  }
} 