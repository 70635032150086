import { Entity, EntityData } from "@shared/entities/Entity";

export type PlayerData = EntityData & {
  completedAchievements: string[];
  lore: string;
  title: string;
  moveSpeed: number;
};


export class Player extends Entity {
  completedAchievements: Set<string>;
  lore: string;
  title: string;
  moveSpeed: number;

  constructor(x: number, y: number, state?: PlayerData) {
    const playerData = {
      id: "player",
      description: "You.",
      name: "Player",
      blocking: true,
      image: null,
      light: 0,
      x: x,
      y: y,
      color: "#FFFFFF",
      add_to_inventory: ["towel"],
      usableEntitiesData: {
        towel: {
          name: "Towel",
          description:
            "A perfectly ordinary towel. Don't leave home without it!",
          svg: `<svg width='32' height='32'>
                        <rect x='4' y='8' width='24' height='16' fill='#87CEEB'/>
                        <rect x='6' y='10' width='20' height='2' fill='#4682B4'/>
                        <rect x='6' y='20' width='20' height='2' fill='#4682B4'/>
                    </svg>`,
        },
      },
      svg: ""
    };

    if (state) {
      super(state);
    } else {
      super(playerData, x, y);
    }
    
    if (state?.completedAchievements) {
      this.completedAchievements = new Set(state.completedAchievements);
    } else {
      this.completedAchievements = new Set();
      this.completedAchievements.add("Started an adventure");
    }
    this.lore = state?.lore || "You are just starting out.";
    this.title = state?.title || "Newb";
    this.moveSpeed = state?.moveSpeed || 15;
  }

  async loadImage() {
    console.log("loadImage in Player");
    const image = await new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        console.log('Image loaded successfully');
        resolve(img);
      };
      img.onerror = (e) => {
        console.error('Failed to load image:', e);
        console.error('Attempted image path:', img.src);
        reject(new Error(`Failed to load player image: ${e}`));
      };
      img.src = '/assets/avatar.webp';
      console.log('Attempting to load image from:', img.src);
    });
    if (image instanceof Image){
      return image;
    }
    throw new Error("couldn't load Player image");
  }

  toJSON() {
    const data: any = {};
    Object.assign(data, this);
    data.completedAchievements = Array.from(data.completedAchievements);
    return data;
  }

}
