import '../styles/shareModal.css';

export class ShareModal {
  constructor() {
    this.modal = document.createElement('div');
    this.modal.className = 'share-modal';
    this.modal.innerHTML = `
      <div class="share-content">
        <h2>Share Your Game</h2>
        <p>Copy this link to share your saved game with others:</p>
        <div class="share-link-container">
          <input type="text" readonly id="shareLink" class="share-link-input">
          <button id="copyShareLink">Copy</button>
        </div>
        <div class="share-buttons">
          <button id="closeShareModal">Close</button>
        </div>
      </div>
    `;

    document.body.appendChild(this.modal);

    // Add event listeners
    this.modal.querySelector('#closeShareModal').addEventListener('click', () => {
      this.hide();
    });

    this.modal.querySelector('#copyShareLink').addEventListener('click', () => {
      const linkInput = this.modal.querySelector('#shareLink');
      linkInput.select();
      document.execCommand('copy');
      
      // Show feedback
      const copyButton = this.modal.querySelector('#copyShareLink');
      const originalText = copyButton.textContent;
      copyButton.textContent = 'Copied!';
      setTimeout(() => {
        copyButton.textContent = originalText;
      }, 2000);
    });
  }

  show(shortId) {
    const shareUrl = `${window.location.origin}?load=${shortId}`;
    this.modal.querySelector('#shareLink').value = shareUrl;
    this.modal.style.display = 'flex';
  }

  hide() {
    this.modal.style.display = 'none';
  }
} 