import { Entity } from "./entities/Entity";
import { TerrainType } from "./types";
import { fromJSON as entityFromJSON } from "./entities/EntityLoader";

export class Level {
  terrainTypes: { [key: string]: TerrainType };
  terrain: string[][];
  width: number;
  height: number;
  placedEntities: Entity[];
  name: string;
  description: string;
  levelId: string;
  ambientLightGrid: number[][];
  discoveredAreas: Uint8ClampedArray;
  musicUrl: string;

  constructor({terrainTypes, terrain, width, height, placedEntities, name, description, levelId, ambientLightGrid, discoveredAreas, musicUrl}: {terrainTypes: { [key: string]: TerrainType }, terrain: string[][], width: number, height: number, placedEntities: Entity[], name: string, description: string, levelId: string, ambientLightGrid: number[][], discoveredAreas: Uint8ClampedArray, musicUrl: string}) {
    this.terrainTypes = terrainTypes;
    this.terrain = terrain;
    this.width = width;
    this.height = height;
    this.placedEntities = placedEntities;
    this.name = name;
    this.description = description;
    this.levelId = levelId;
    this.ambientLightGrid = ambientLightGrid;
    this.discoveredAreas = discoveredAreas;
    this.musicUrl = musicUrl;
  }

  getSwitchLevelEntityForConnection(connectionId: string) {
    const switchLevelEntities = this.placedEntities.filter(entity => entity.switchLevels?.connectionId === connectionId);
    if (switchLevelEntities.length === 0) throw new Error("Switch level entities not found");
    if (switchLevelEntities.length > 1) throw new Error("Multiple switch level entities found");
    const switchLevelEntity = switchLevelEntities[0];
    return switchLevelEntity;
  }

  static fromJSON(data: any) {
    data.placedEntities = data.placedEntities.map((entityData: any) => {
        // Create new Entity with position and data from entities dictionary
        return entityFromJSON(entityData);
    });
    const level = new Level(data);
    return level;
  }
  toJSON() {
    const data: any = {};
    Object.assign(data, this);
    data.placedEntities = data.placedEntities.map((entity: Entity) => entity.toJSON());
    return data;
  }

}